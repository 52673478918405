import React, {Fragment} from 'react';
import styled from 'styled-components';

import {LoadingDots} from 'src/components';
import {Theme} from 'src/theme';

interface ButtonProps {
  onClick(): void;
  text: string;
  loading?: boolean;
  buttonStyles?: React.CSSProperties;
  buttonTextStyles?: React.CSSProperties;
  href?: string;
}

export const Button: React.FC<ButtonProps> = ({
  onClick,
  text,
  loading,
  buttonStyles,
  buttonTextStyles,
  href,
}) => {
  return (
    <Fragment>
      {href ? (
        <LinkWrapper onClick={onClick} style={buttonStyles} href={href}>
          <ButtonText style={buttonTextStyles}>
            {loading ? <LoadingDots /> : text}
          </ButtonText>
        </LinkWrapper>
      ) : (
        <ButtonWrapper onClick={onClick} style={buttonStyles}>
          <ButtonText style={buttonTextStyles}>
            {loading ? <LoadingDots /> : text}
          </ButtonText>
        </ButtonWrapper>
      )}
    </Fragment>
  );
};

const ButtonWrapper = styled.div`
  width: 100%;
  height: 48px;
  border-radius: 2px;
  background-color: ${Theme.colors.purple};
  box-shadow: 0px 6px 14px rgba(84, 105, 212, 0.1);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LinkWrapper = styled.a`
  width: 100%;
  height: 48px;
  border-radius: 2px;
  background-color: ${Theme.colors.purple};
  box-shadow: 0px 6px 14px rgba(84, 105, 212, 0.1);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

const ButtonText = styled.div`
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  color: ${Theme.colors.white};
`;
