import React from 'react';
import styled from 'styled-components';

import {Theme} from 'src/theme';

interface ScheduleItemProps {
  label: string;
  value: string;
  isTotal?: boolean;
}

export const ScheduleItem = React.memo<ScheduleItemProps>(
  ({label, value, isTotal}) => {

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return (
      <ItemWrapper>
        <Labeltext>
          {label}
          {isTotal && <Star>*</Star>}
        </Labeltext>
        <ValueText>{formatter.format(+value)}</ValueText>
      </ItemWrapper>
    );
  },
);

const Star = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  position: relative;
  top: -3px;
  color: ${Theme.colors.muted};
`;

const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
`;

const Labeltext = styled(Text)`
  color: ${Theme.colors.muted};
`;

const ValueText = styled(Text)`
  color: ${Theme.colors.black2};
`;
