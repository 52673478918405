import React from 'react';
import styled from 'styled-components';

import {Theme} from 'src/theme';

interface ErrorMessageProps {
  message: string;
}

export const ErrorMessage = React.memo<ErrorMessageProps>(({message}) => {
  return <ErrorMessageText>{message}</ErrorMessageText>;
});

const ErrorMessageText = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
  color: ${Theme.colors.red};
`;
