import {createReducer} from 'deox';
import {produce} from 'immer';

import {
  getPayment,
  getLoanPayment,
  addLoanPayment,
  getPaymentHistory,
  getCompany,
  addProofFile,
  deleteProofFile,
  getXeroAuthorizationLink,
  setPayNowAmount,
  setPayInfo,
  addPaymentScheduler,
  setActivePayment,
  getPaymentById,
  getActivePayments,
  setPaymentId
} from './actions';
import {PaymentsReducerProps} from './types';
import {AsyncStatus} from 'src/types';

const initialState: PaymentsReducerProps = {
  id: 'string',
  payment: {
    id: 0,
    tax_company: {
      tax_name: 'string',
      tax_frequency: 'string',
    },
    total_amount: 'string',
    due_date: 'string',
    is_active: false,
    paid_date: 'string',
    period: 'string',
    created_by: 'string',
  },
  activePayments: [],
  activePayment: {
    id: 0,
    tax_company: {
      tax_name: 'string',
      tax_frequency: 'string',
    },
    total_amount: 'string',
    due_date: 'string',
    is_active: false,
    paid_date: 'string',
    period: 'string',
    created_by: 'string',
  },
  loanPayment: {
    id: 0,
    loan_amount: 0,
    application_date: '',
    loan_terms: 0,
    interest: '',
    lumi_id: '',
    receipt_date: '',
    updated_at: '',
    status: '',
    payment: 0,
  },
  paymentScheduler: {
    total: '',
    scheduler: [],
  },
  paymentHistory: [],
  companyName: '',
  getPaymentReqStat: AsyncStatus.NONE,
  getLoanPaymentReqStat: AsyncStatus.NONE,
  getPaymentByIdReqStat: AsyncStatus.NONE,
  addLoanPaymentReqStat: AsyncStatus.NONE,
  getActivePaymentsReq: AsyncStatus.NONE,
  addProofFileReqStat: AsyncStatus.NONE,
  deleteProofFileReqStat: AsyncStatus.NONE,
  addPaymentSchedulerReqStat: AsyncStatus.NONE,
  proofImages: [],
  proofImagesPoints: 0,
  xeroUrl: '',
  pay_now_amount: 0,
  getXeroAuthorizationLinkStat: AsyncStatus.NONE,
  payInfo: {
    totalAmount: 0,
    payNowAmount: 0,
    payLaterAmount: 0,
  },
};

export const PaymentsReducer = createReducer(initialState, (handleAction) => [
  //GET PAYMENT
  handleAction(getPayment.request, (state) =>
    produce(state, (draft) => {
      draft.getPaymentReqStat = AsyncStatus.LOADING;
    }),
  ),
  handleAction(getPaymentById.request, (state) =>
    produce(state, (draft) => {
      draft.getPaymentByIdReqStat = AsyncStatus.LOADING;
    }),
  ),
  handleAction(getPaymentById.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.activePayment = payload;
      draft.getPaymentByIdReqStat = AsyncStatus.SUCCESS;
    }),
  ),
  handleAction(getPaymentById.fail, (state) =>
    produce(state, (draft) => {
      draft.getPaymentByIdReqStat = AsyncStatus.FAIL;
    }),
  ),
  handleAction(getPaymentById.reset, (state) =>
    produce(state, (draft) => {
      draft.getPaymentByIdReqStat = AsyncStatus.NONE;
    }),
  ),
  handleAction(getPayment.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.payment = payload;
      draft.getPaymentReqStat = AsyncStatus.SUCCESS;
    }),
  ),
  handleAction(getPayment.fail, (state) =>
    produce(state, (draft) => {
      draft.getPaymentReqStat = AsyncStatus.FAIL;
    }),
  ),


  //GET_ACTIVE_PAYMENTS
  handleAction(getActivePayments.request, (state) =>
    produce(state, (draft) => {
      draft.getActivePaymentsReq = AsyncStatus.LOADING;
    }),
  ),
  handleAction(getActivePayments.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.activePayments = payload;
      draft.getActivePaymentsReq = AsyncStatus.SUCCESS;
    }),
  ),
  handleAction(getActivePayments.fail, (state) =>
    produce(state, (draft) => {
      draft.getActivePaymentsReq = AsyncStatus.FAIL;
    }),
  ),


  //GET LOAN PAYMENT
  handleAction(getLoanPayment.request, (state) =>
    produce(state, (draft) => {
      draft.getLoanPaymentReqStat = AsyncStatus.LOADING;
    }),
  ),
  handleAction(getLoanPayment.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.loanPayment = {...payload};
      draft.getLoanPaymentReqStat = AsyncStatus.SUCCESS;
    }),
  ),
  handleAction(getLoanPayment.fail, (state) =>
    produce(state, (draft) => {
      draft.getLoanPaymentReqStat = AsyncStatus.FAIL;
    }),
  ),

  //ADD LOAN PAYMENT
  handleAction(addLoanPayment.request, (state) =>
    produce(state, (draft) => {
      draft.addLoanPaymentReqStat = AsyncStatus.LOADING;
    }),
  ),
  handleAction(addLoanPayment.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.loanPayment = payload;
      draft.addLoanPaymentReqStat = AsyncStatus.SUCCESS;
    }),
  ),
  handleAction(addLoanPayment.fail, (state) =>
    produce(state, (draft) => {
      draft.addLoanPaymentReqStat = AsyncStatus.FAIL;
    }),
  ),

  //GET PAYMENT HISTORY
  handleAction(getPaymentHistory.request, (state) =>
    produce(state, (draft) => {
      draft.getLoanPaymentReqStat = AsyncStatus.LOADING;
    }),
  ),
  handleAction(getPaymentHistory.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.paymentHistory = payload;
      draft.getLoanPaymentReqStat = AsyncStatus.SUCCESS;
    }),
  ),
  handleAction(getPaymentHistory.fail, (state) =>
    produce(state, (draft) => {
      draft.addLoanPaymentReqStat = AsyncStatus.FAIL;
    }),
  ),

  handleAction(setActivePayment, (state, {payload}) =>
    produce(state, (draft) => {
    draft.activePayment = payload;
  }),
),

  //GET COMPANY DETAILS
  handleAction(getCompany.request, (state) =>
    produce(state, (draft) => {
      draft.getLoanPaymentReqStat = AsyncStatus.LOADING;
    }),
  ),
  handleAction(getCompany.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.companyName = payload;
      draft.getLoanPaymentReqStat = AsyncStatus.SUCCESS;
    }),
  ),
  handleAction(getCompany.fail, (state) =>
    produce(state, (draft) => {
      draft.addLoanPaymentReqStat = AsyncStatus.FAIL;
    }),
  ),

  // ADD PROOF FILE
  handleAction(addProofFile.request, (state) =>
    produce(state, (draft) => {
      draft.addProofFileReqStat = AsyncStatus.LOADING;
    }),
  ),
  handleAction(addProofFile.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.proofImages = state.proofImages
        ? [...state.proofImages, payload]
        : [payload];
      // draft.proofImagesPoints = state.proofImagesPoints
      //   ? state.proofImagesPoints + Number(payload.points)
      //   : Number(payload.points);
      draft.addProofFileReqStat = AsyncStatus.SUCCESS;
    }),
  ),
  handleAction(addProofFile.fail, (state) =>
    produce(state, (draft) => {
      draft.addProofFileReqStat = AsyncStatus.FAIL;
    }),
  ),

   // DELETE PROOF FILE
   handleAction(deleteProofFile.request, (state) =>
   produce(state, (draft) => {
     draft.deleteProofFileReqStat = AsyncStatus.LOADING;
   }),
 ),
 handleAction(deleteProofFile.success, (state) =>
   produce(state, (draft) => {
     draft.proofImages = []
    //  [...state.proofImages].filter(
    //    (item) => item.id === payload
    //  )
       
     draft.deleteProofFileReqStat = AsyncStatus.SUCCESS;
   }),
 ),
 handleAction(deleteProofFile.fail, (state) =>
   produce(state, (draft) => {
     draft.deleteProofFileReqStat = AsyncStatus.FAIL;
   }),
 ),

  //GET XERO LINK
  handleAction(getXeroAuthorizationLink.request, (state) =>
    produce(state, (draft) => {
      draft.getXeroAuthorizationLinkStat = AsyncStatus.LOADING;
    }),
  ),
  handleAction(getXeroAuthorizationLink.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.xeroUrl = payload;
      draft.getXeroAuthorizationLinkStat = AsyncStatus.SUCCESS;
    }),
  ),
  handleAction(getXeroAuthorizationLink.fail, (state) =>
    produce(state, (draft) => {
      draft.getXeroAuthorizationLinkStat = AsyncStatus.FAIL;
    }),
  ),

  //SEY PAY NOW AMOUNT
  handleAction(setPayNowAmount.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.pay_now_amount = payload;
    }),
  ),

  handleAction(setPaymentId, (state, {payload}) =>
    produce(state, (draft) => {
      draft.id = payload;
    }),
  ),

  //ADD PAY DETAILS
  handleAction(setPayInfo, (state, {payload}) =>
    produce(state, (draft) => {
      draft.payInfo = payload;
    }),
  ),

  // ADD PAYMENT SCHEDULER
  handleAction(addPaymentScheduler.request, (state) =>
    produce(state, (draft) => {
      draft.addPaymentSchedulerReqStat = AsyncStatus.LOADING;
    }),
  ),
  handleAction(addPaymentScheduler.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.addPaymentSchedulerReqStat = AsyncStatus.SUCCESS;
      draft.paymentScheduler = payload;
    }),
  ),
  handleAction(addPaymentScheduler.fail, (state) =>
    produce(state, (draft) => {
      draft.addPaymentSchedulerReqStat = AsyncStatus.FAIL;
    }),
  ),
]);
