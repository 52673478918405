/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import {LogoSVG, BurgerMenu, CloseCross, MenuItemIcon} from 'src/assets';
import {Theme} from 'src/theme';
import {Link, useHistory} from 'react-router-dom';

// import {RootState} from 'src/store/types';
// import {useSelector} from 'react-redux';
import {useAction} from 'src/utils/hooks';
import {getCompany} from 'src/store/payments/actions';
import {logOut} from 'src/store/auth/actions';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/types';
import {getActivePayments} from 'src/store/payments/actions';

interface HeaderProps {
  isLogo?: boolean,
  onDisplay?: () => void,
}

export const Header = React.memo<HeaderProps>(({isLogo = true, onDisplay}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const {displayHome} = useSelector((state: RootState) => state.auth);
  const {activePayment} = useSelector((state: RootState) => state.payments);

  
   
  let history = useHistory();
  //  
  // const {companyName} = useSelector((state: RootState) => state.payments);
  const getCompanyAction = useAction(getCompany.request);
  const logoutAction = useAction(logOut);
  const activePaymentsAction = useAction(getActivePayments.request);


  const logoutAct = () => {
    logoutAction();
    history.push('/');
  };

  useEffect(() => {
    getCompanyAction()
    activePaymentsAction();
  }, []);

  const handleMenuVisibility = () => {
    onDisplay?.();
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <MenuBlock>
      <Wrapper isLogo={isLogo}>
          {isLogo || isMenuOpen ? (
            <LogoCont>
              <LogoSVG />
            </LogoCont>
          ) : (
            <Text>PayMyTax</Text>
          )}
          {isMenuOpen ? (
            <CloseCross onClick={handleMenuVisibility} />
          ) : (
            <BurgerMenu onClick={handleMenuVisibility} />
          )}
        </Wrapper>
      {isMenuOpen && (
        <Menu>
          {/* <Wrapper>
            <LogoCont>
              <LogoSVG />
            </LogoCont>
            <CloseCross onClick={handleMenuVisibility} />
          </Wrapper> */}
          {displayHome && activePayment.is_active ? <Link
            to="/home"
            style={{
              color: '#404040',
              textDecoration: 'none',
            }}
            onClick={() => {
                setIsMenuOpen(false);
                onDisplay?.();
            }}
            // activeStyle={{
            //   fontWeight: 'bold',
            //   color: 'red',
            // }}
          >
            <MenuItem>
              Home
              <MenuItemIcon />
            </MenuItem>
          </Link>: null}
          <Link
            to="/contact-us"
            style={{
              color: '#404040',
              textDecoration: 'none',
            }}
            onClick={() => {
              if (history.location.pathname === '/contact-us') {
                setIsMenuOpen(false);
                onDisplay?.();
              }
            }}
            // activeStyle={
            //   fontWeight: 'bold',
            //   color: 'red',
            // }}
          >
            <MenuItem>
              Contact us
              <MenuItemIcon />
            </MenuItem>
          </Link>
          <HorizontalLine />
          <Link
            to="/payment-history"
            style={{
              color: '#404040',
              textDecoration: 'none',
            }}
            onClick={() => {
              if (history.location.pathname === '/payment-history') {
                setIsMenuOpen(false);
                onDisplay?.();
              }
            }}>
            <MenuItem>
              Tax payment history
              <MenuItemIcon />
            </MenuItem>
          </Link>
          <HorizontalLine />
          <MenuItem onClick={() => logoutAct()}>Log out</MenuItem>
        </Menu>
          )}
      </MenuBlock>
    
  );
});

const MenuBlock = styled.div`
  display: absolute;
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 1020px;
  margin: auto;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: ${(props: HeaderProps) =>
    props.isLogo ? 'none' : '0px 1px 1px rgba(0, 0, 0, 0.1)'};
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: ${Theme.colors.blue1};
`;

const Menu = styled.div`
  width: 100%;
  height: 82vh;
  margin: auto;
  overflow: hidden;
  background-color: white;
`;

const LogoCont = styled.div`
  height: 30px;
  width: 30px;
`;

const MenuItem = styled.div`
  height: 60px;
  padding: 20px;
  max-width: 1020px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #404040;
  cursor: pointer;
`;

const HorizontalLine = styled.div`
  height: 1px;
  margin: auto;
  width: 95%;
  max-width: 960px;
  background-color: #f3f3f3;
`;
