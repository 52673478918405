import {ActionType, getType} from 'deox';
import {SagaIterator} from 'redux-saga';
import {call, put, takeEvery} from 'redux-saga/effects';
import {Contact} from 'src/services';

import {sendFeedback} from './actions';

function* sendFeedbackSaga({
  payload: {first_name, last_name, email, message},
}: ActionType<typeof sendFeedback.request>): SagaIterator {
  try {
     
    const res = yield call(Contact.ContactReq, {
      first_name,
      last_name,
      email,
      message,
    });
    if (res.status === 200) {
      yield put(sendFeedback.success());
      yield put(sendFeedback.fail([]));
    } else { 
      yield put(sendFeedback.fail(res.data));
    }
  } catch (e) {
    yield put(sendFeedback.fail([]));
  }
}

export function* watchContact(): SagaIterator {
  yield takeEvery(getType(sendFeedback.request), sendFeedbackSaga);
}
