import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {
  Input,
  GoBack,
  PageWrapper,
  Button,
  ErrorMessage,
  Logo,
} from 'src/components';
import {Theme} from 'src/theme';
import {RootState} from 'src/store/types';
import {useAction} from 'src/utils/hooks';
import {login} from 'src/store/auth/actions';
import {HeaderMain} from 'src/components';
import {AsyncStatus} from 'src/types';
// import {logOut} from 'src/store/auth/actions';

export const Login = React.memo(() => {
  const [phone, setPhone] = useState<string>('');

  const history = useHistory();
  const {loginReqStat} = useSelector((state: RootState) => state.auth);
  const {loginErrorMessages} = useSelector((state: RootState) => state.errors);
  const resetLoginAsyncStatus = useAction(login.reset);
  const loginAction = useAction(login.request);
  // const logoutAction = useAction(logOut);

  // useEffect(() => {
  //   logoutAction();
  // }, [logoutAction])

  useEffect(() => {
    if (loginReqStat === AsyncStatus.SUCCESS) {
      history.push('/login/verify');
      resetLoginAsyncStatus();
    }
  }, [history, loginReqStat, resetLoginAsyncStatus]);

  const onContinueButtonClick = () => {
    loginAction({phone});
  };

  const onInputChange = (event: any) => {
    setPhone(event.target.value.replace(/[^0-9]/g, ''));
  };

  return (
    <Wrapper>
    <HeaderWraper>
      <HeaderMain/>
    </HeaderWraper>
    <PageWrapper>
      <GoBack />
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <Header>Log in</Header>
      <Input
        onChange={onInputChange}
        type="text"
        labelText="Your ABN number"
        placeholder="01 000 000 001"
        mask={'99 999 999 999'}
        value={phone}
      />
      {loginErrorMessages && !!loginErrorMessages.length && (
        <ErrorMessage message={loginErrorMessages[0]} />
      )}
      <Button
        onClick={onContinueButtonClick}
        text="Continue"
        loading={loginReqStat === AsyncStatus.LOADING}
      />
    </PageWrapper>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%;
`;

const HeaderWraper = styled.div`
  width: 100%;
  height: 50vh;
`;

const Header = styled.h2`
  font-size: 28px;
  font-weight: 600;
  line-height: 21px;
  color: ${Theme.colors.black2};
  margin-bottom: 24px;
  margin-top: 93px;
`;

const LogoWrapper = styled.div`
  position: absolute;
  left: 48.2%;
`;
