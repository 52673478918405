import React from 'react';
import Picker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import {format} from 'date-fns';

import {Theme} from 'src/theme';
import {ArrowDownSVG} from 'src/assets';

interface DatePickerProps {
  value: Date;
  onChange(val: Date): void;
  label: string;
}

export const DatePicker = React.memo<DatePickerProps>(
  ({value, onChange, label}) => {
    return (
      <PickerWrapper>
        <PickerLabel>{label}</PickerLabel>
        <CustomPicker value={format(value, 'd MMM yyyy')} onChange={onChange} />
        <ArrowContainer>
          <ArrowDownSVG />
        </ArrowContainer>
      </PickerWrapper>
    );
  },
);

const CustomPicker = styled(Picker)`
  width: 100%;
  background-color: ${Theme.colors.lightGray};
  border-radius: 2px;
  box-shadow: inset 0px 4px 14px rgba(167, 167, 167, 0.1);
  color: ${Theme.colors.black2};
  font-size: 14px;
  line-height: 14px;
  padding: 17px 18px;
`;

const PickerWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 13px;
  width: 100%;
`;

const PickerLabel = styled.label`
  font-size: 13px;
  line-height: 13px;
  color: ${Theme.colors.black2};
  margin-bottom: 4px;
`;

const ArrowContainer = styled.div`
  position: absolute;
  width: 10px;
  height: 5px;
  bottom: 31px;
  right: 17px;
`;
