/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';

import {PageWrapper, Header, PaymentInfo} from 'src/components';
import {Theme} from 'src/theme';
import {RootState} from 'src/store/types';
// import {useAction} from 'src/utils/hooks';
// import {getPaymentHistory} from 'src/store/payments/actions';
import {moneyAmountFormat} from 'src/helpers';
// import {getPayment} from 'src/store/payments/actions';
import { getPaymentHistory, getActivePayments } from 'src/store/payments/actions';
import { useAction } from 'src/utils/hooks';

export const PaymentHistory = React.memo(({setPage}: any) => {
  // const {payments} = useSelector((state: RootState) => state.auth);
  let {paymentHistory} = useSelector((state: RootState) => state.payments);
  let {activePayments} = useSelector((state: RootState) => state.payments);
  const [display, setDisplay] = useState(true);
  const historyPaymentAction = useAction(getPaymentHistory.request);
  const activePaymentsAction = useAction(getActivePayments.request);

  const reloadCount = Number(sessionStorage.getItem('reloadCount')) || 0;
  
  useEffect(() => {
    if(reloadCount < 1) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      setTimeout(() => window.location.reload(),1000);
    } else {
      sessionStorage.removeItem('reloadCount');
    }
    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    historyPaymentAction();
    activePaymentsAction();
  }, []);

  const ids = activePayments.map(item => item.id);

  useEffect(() => {
    setPage(true);
    return () => setPage(false);
  }, []);
  return (
    <Fragment1>
      <Header
        isLogo={false}
        onDisplay={() => {
          setDisplay(!display);
        }}
      />
      {display && (
        <PageWrapper>
          <HeaderText>Tax payment history</HeaderText>
          <Container paymentHistory={paymentHistory} activePayments={activePayments}>
          {!!activePayments.length &&
            activePayments.map((el, index) => {
              return (
                !!el.is_active && (
                  <PaymentWrapper
                  paymentHistory={paymentHistory}
                  activePayments={activePayments}
                  key={el.id}
                  >
                    <PaymentInfo
                      typeOfActivity={el.tax_company.tax_name}
                      period={el.period}
                      dueDate={el.due_date}
                      totalAmountDue={`$${moneyAmountFormat(el.total_amount)}`}
                      button={true}
                      payment={el}
                      color={true}
                      key={index}
                    />
                  </PaymentWrapper>
                )
              );
            })}
          {!!paymentHistory.length &&
            paymentHistory.filter(item => !ids.includes(item.id)).map((el) => (
              <PaymentWrapper paymentHistory={paymentHistory} activePayments={activePayments}  key={el.id}>
                <PaymentInfo
                  typeOfActivity={el.tax_type}
                  period={el.period}
                  dueDate={el.due_date}
                  totalAmountDue={`$${moneyAmountFormat(el.total_amount)}`}
                />
              </PaymentWrapper>
            ))}
            </Container>
        </PageWrapper>
      )}
    </Fragment1>
  );
});

const HeaderText = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  color: ${Theme.colors.black};
  margin: 16px 0 0 0;
`;

const PaymentWrapper = styled.div<{paymentHistory: any,activePayments: any}>`
  overflow: auto;
  margin-top: 18px;
  height: 200px;
  margin-right: 12px;
  padding-right: 0px;
  width: 32%;
  @media (max-width: 768px) {
    width: 95%;
    margin-right: 0;
  }
  box-shadow: 0px 4px 12px rgb(0 0 0 / 10%);
`;
const Container = styled.div<{paymentHistory: any, activePayments: any}>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    width: 100%;
    display: block;
  }
  justify-content: flex-start;
 height: 100%;
`;
const Fragment1 = styled.div`
 min-height: 82vh;
`;
