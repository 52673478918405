import React, {Fragment, useState} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';

import {PageWrapper, GoBack, Input, Button} from 'src/components';
import {Theme} from 'src/theme';

export const BusinessInfo = React.memo(() => {
  const [monthesInBusiness, setMonthesInBusiness] = useState<string>('');
  const [averageMonthlyTurnover, setAverageMonthlyTurnover] = useState<string>(
    '',
  );
  const [website, setWebsite] = useState<string>('');
  const [numberOfEmployees, setNumberOfEmployees] = useState<string>('');

  const history = useHistory();

  return (
    <Fragment>
      <PageWrapper>
        <GoBack />
        <Header>Business info</Header>
        <Input
          value={monthesInBusiness}
          onChange={(e: any) =>
            (Number(e.target.value) || e.target.value === '') &&
            setMonthesInBusiness(e.target.value)
          }
          type="text"
          labelText="Months in business"
        />
        <Input
          value={averageMonthlyTurnover}
          onChange={(e: any) =>
            (Number(e.target.value) || e.target.value === '') &&
            setAverageMonthlyTurnover(e.target.value)
          }
          type="text"
          labelText="Average monthly turnover"
          leftIcon="$"
        />
        <Input
          value={website}
          onChange={(e: any) => setWebsite(e.target.value)}
          type="text"
          labelText="Website"
        />
        <Input
          value={numberOfEmployees}
          onChange={(e: any) =>
            (Number(e.target.value) || e.target.value === '') &&
            setNumberOfEmployees(e.target.value)
          }
          type="text"
          labelText="Number of employees"
        />
      </PageWrapper>

      <ButtonWrapper>
        <Button
          text="Next step"
          onClick={() => history.push('/new-payment/identity-proof')}
        />
      </ButtonWrapper>
    </Fragment>
  );
});

const Header = styled.h2`
  fonr-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: ${Theme.colors.black};
  margin-top: 80px;
  margin-bottom: 17px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 37px;
  padding: 0 20px;
  box-sizing: border-box;
`;
