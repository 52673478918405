import {SagaIterator} from 'redux-saga';
import {all, EffectReturnType, fork} from 'typed-redux-saga';

import {watchAuth} from './auth/sagas';
import {watchPayment} from './payments/sagas';
import {watchIllion} from './illion/sagas';
import {watchEzidebit} from './ezidebit/sagas';
import {watchContact} from './contactUs/sagas';

export default function* rootSaga(): Generator<EffectReturnType<SagaIterator>> {
  yield all([
    fork(watchAuth),
    fork(watchPayment),
    fork(watchIllion),
    fork(watchEzidebit),
    fork(watchContact),
  ]);
}
