import {createAction} from 'deox';

import {
  LoanPayment,
  LoanPaymentRespond,
  Payment,
  PaymentHistory,
  File,
  PayAmountData,
  PaymentScheduler,
} from './types';

export const getPayment = {
  request: createAction('payments/GET_PAYMENT'),
  success: createAction(
    'payments/GET_PAYMENT_SUCCESS',
    (resolve) => (payload: Payment) => resolve(payload),
  ),
  fail: createAction('payments/GET_PAYMENT_FAIL'),
};

export const getPaymentById = {
  request: createAction(
    'payments/GET_PAYMENT_BY_ID',
    (resolve) => (payload: string) => resolve(payload),
  ),
  success: createAction(
    'payments/GET_PAYMENT_BY_ID_SUCCESS',
    (resolve) => (payload: Payment) => resolve(payload),
  ),
  fail: createAction('payments/GET_PAYMENT_BY_ID_FAIL'),
  reset: createAction('payments/GET_PAYMENT_BY_ID_RESET'),
};

export const getActivePayments = {
  request: createAction(
    'payments/GET_ACTIVE_PAYMENTS',
  ),
  success: createAction(
    'payments/GET_ACTIVE_PAYMENTS_SUCCESS',
    (resolve) => (payload: Payment[]) => resolve(payload),
  ),
  fail: createAction('payments/GET_ACTIVE_PAYMENT_FAIL'),
  reset: createAction('payments/GET_ACTIVE_PAYMENT_RESET'),
} 


export const setActivePayment = createAction(
  'payments/SET_ACTIVE_PAYMENT',
  (resolve) => (payload: Payment) => resolve(payload),
);

export const setPaymentId = createAction(
  'payments/SET_PAYMENT_ID',
  (resolve) => (payload: string) => resolve(payload),
);

export const getLoanPayment = {
  request: createAction(
    'payments/GET_LOAN_PAYMENT',
    (resolve) => (payload: string) => resolve(payload),
  ),
  success: createAction(
    'payments/GET_LOAD_PAYMENT_SUCCESS',
    (resolve) => (payload: LoanPaymentRespond) => resolve(payload),
  ),
  fail: createAction('payments/GET_LOAN_PAYMENTS_FAIL'),
};

export const addLoanPayment = {
  request: createAction(
    'payments/ADD_LOAN_PAYMENT',
    (resolve) => (payload: LoanPayment) => resolve(payload),
  ),
  //success: createAction('payments/GET_LOAN_PAYMENT_SUCCESS'),
  success: createAction(
    'payments/GET_LOAN_PAYMENT_SUCCESS',
    (resolve) => (payload: LoanPaymentRespond) => resolve(payload),
  ),
  fail: createAction('payments/GET_LOAN_PAYMENT_FAIL'),
};

export const getPaymentHistory = {
  request: createAction('payments/GET_PAYMENT_HISTORY'),
  success: createAction(
    'payments/GET_PAYMENT_HISTORY_SUCCESS',
    (resolve) => (payload: PaymentHistory[]) => resolve(payload),
  ),
  fail: createAction('payments/GET_PAYMENT_HISTORY_FAIL'),
};

export const getCompany = {
  request: createAction('payments/GET_COMPANY'),
  success: createAction(
    'payments/GET_COMPANY_SUCCESS',
    (resolve) => (payload: string) => resolve(payload),
  ),
  fail: createAction('payments/GET_COMPANY_FAIL'),
};

export const addProofFile = {
  request: createAction(
    'payments/ADD_PROOF_FILE',
    (resolve) => (payload: FormData) => resolve(payload),
  ),
  success: createAction(
    'payments/ADD_PROOF_FILE_SUCCESS',
    (resolve) => (payload: File) => resolve(payload),
  ),
  fail: createAction('payments/ADD_PROOF_FILE_FAIL'),
};

export const deleteProofFile = {
  request: createAction(
    'payments/DELETE_PROOF_FILE',
    (resolve) => (payload: number) => resolve(payload),
  ),
  success: createAction(
    'payments/DELETE_PROOF_FILE_SUCCESS',
    (resolve) => (payload: number) => resolve(payload),
  ),
  fail: createAction('payments/DELETE_PROOF_FILE_FAIL'),
};



export const getXeroAuthorizationLink = {
  request: createAction('payments/GET_XERO_AUTH'),
  success: createAction(
    'payments/GET_XERO_AUTH_SUCCESS',
    (resolve) => (payload: string) => resolve(payload),
  ),
  fail: createAction('payments/GET_XERO_AUTH_FAIL'),
};

export const setPayNowAmount = {
  success: createAction(
    'payments/SET_PAY_NOW_PAYMENT_AMOUNT',
    (resolve) => (payload: number) => resolve(payload),
  ),
};

export const setPayInfo = createAction(
  'payments/ADD_PAY_INFO',
  (resolve) => (payload: PayAmountData) => resolve(payload),
);

export const addPaymentScheduler = {
  request: createAction(
    'payment/ADD_PAYMENT_SCHEDULER',
    (resolve) => (payload: {amount: string}) => resolve(payload),
  ),
  success: createAction(
    'payment/ADD_PAYMENT_SCHEDULER_SUCCESS',
    (resolve) => (payload: PaymentScheduler) => resolve(payload),
  ),
  fail: createAction('payment/ADD_PAYMENT_SCHEDULER_FAIL'),
};
