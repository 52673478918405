/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import styled from 'styled-components';

interface TermsProps {}

export const Terms = React.memo<TermsProps>(() => {
  return (
    <Wrapper>
      <Header>
        <Number>1.</Number> General
      </Header>
      <Text>
        These terms and conditions (Terms) govern the terms on which you may
        access and use PayMyTax and all associated websites, applications,
        platforms, software and services (Application), published or provided by
        Tax Hitech Pty Ltd ACN 642 032 666 and its associated entities (Tax
        Hitech, us or we). These Terms constitutes a binding contract between
        you and Tax Hitech.
      </Text>
      <Text>
        Your access to and use of the Application constitutes an acknowledgement
        that you have read, understood and agreed to these Terms as at the time
        of that access or use. If you do not accept these Terms, you must
        refrain from using the Application.
      </Text>
      <Text>
        Tax Hitech reserves the right to change these Terms at its discretion
        without notice. Amendments will be effective immediately upon
        notification on the Application. Your continued use of the Application
        following such notification will represent an agreement by you to be
        bound by the Terms as amended.
      </Text>
      <Text>
        If you are agreeing to these Terms on behalf of a another person, you
        represent to Tax Hitech that you have legal authority to bind that
        person.
      </Text>
      <Text>Use of the Application is at your sole risk.</Text>

      <Header>
        <Number>2.</Number> Cookies
      </Header>
      <Text>You acknowledge and agree that:</Text>
      <Text>
        Tax Hitech may use cookies (both persistent and session), web and system
        logs, web beacons and other similar tools (Cookies) to identify your
        computer or device and for other purposes, including to operate and
        personalise the Application for you;
      </Text>
      <Text>
        the use of Cookies may result in the collection of your Personal
        Information, which we may use in accordance with our Privacy Policy;
      </Text>
      <Text>
        and if you delete or refuse to accept Cookies via your internet browser,
        we may not be able to provide you with the full functionality of our
        Application.
      </Text>

      <Header>
        <Number>3.</Number> Accounts
      </Header>
      <Text>Registration</Text>
      <Text>
        You will need to register for an account in order to access the
        Application. All registration information that you provide must be
        accurate, current and complete. You must update this information as and
        when it changes.
      </Text>
      <Text>
        Where the registration information includes Personal Information, it
        will be collected and stored in accordance with our Privacy Policy.
      </Text>
      <Text>Account security</Text>
      <Text>
        During the registration process, you will be required to establish a
        password. You are responsible for safeguarding this information and any
        other confidential identifiers.
      </Text>
      <Text>Each account is exclusive and non-transferrable.</Text>
      <Text>
        You must immediately notify Tax Hitech of any, or any suspected,
        unauthorised use of your account.
      </Text>
      <Text>
        You are responsible for all actions and losses arising from your failure
        to keep your information secure and confidential.
      </Text>
      <Text>Notices in relation to your account</Text>
      <Text>
        You agree that Tax Hitech may provide you with notices in relation to
        your account, the Application or these Terms via a notification facility
        within the Application, and that such notices shall constitute the
        provision of written notices for all purposes (including but not limited
        to any requirement for written notice under these Terms).
      </Text>

      <Header>
        <Number>4.</Number> Collection notice
      </Header>
      <Text>
        We collect your Personal Information to assist you with the facilitation
        of the payment of your Tax Debts and for any other purpose set out in
        these Terms, our Application, or Privacy Policy (Services).
      </Text>
      <Text>
        To assist us in delivering the Services, we may disclose information to
        third parties (including information technology suppliers, communication
        suppliers and our business partners), as required by law and as
        otherwise permitted in accordance with our Privacy Policy.
      </Text>
      <Text>
        If all information we request (whether Personal Information or
        otherwise) is not provided promptly, accurately and completely, we may
        not be able to provide the Services, which may cause your Tax Debt to
        increase.
      </Text>
      <Text>Our Privacy Policy explains:</Text>
      <Text>
        how we store and use, and how you may access and correct your Personal
        Information;
      </Text>
      <Text>
        how you can lodge a complaint regarding the handling of your Personal
        Information;
      </Text>
      <Text> and how we will handle any complaint.</Text>
      <Text>
        By providing your Personal Information to us, you consent to the
        collection, use, storage and disclosure of that information as described
        in the Privacy Policy and these Terms.
      </Text>

      <Header>
        <Number>5.</Number> Accuracy, completeness and timeliness of Information
      </Header>
      <Text>
        Any Information generated by the Application for your benefit is not
        comprehensive and is only a summary of the subject matter covered (and
        should not be relied upon for accounting, financial or other purposes).
        While we will use all reasonable attempts to promote the accuracy and
        completeness of the Information, to the extent permitted by law,
        including the Australian Consumer Law, we make no warranty regarding
        Information we generate and display on the Application.
      </Text>

      <Header>
        <Number>6.</Number> Restrictions and responsibilities
      </Header>
      <Text> You must not, either through the Application or otherwise:</Text>
      <Text>
        reverse engineer, decompile or dissemble the Application, or circumvent
        any security or authentication measures or attempt to gain unauthorised
        access to the Application or network that hosts the Application;
      </Text>
      <Text>
        upload, submit or otherwise provide content to the Application that is
        knowingly incorrect, deceptive, or fraudulent;
      </Text>
      <Text>
        use any network monitoring or discovery software to determine the site
        architecture, or extract information about usage, individual identities
        or Users from the Application;
      </Text>
      <Text>
        use any software, device or manual process to monitor or copy the
        Application (including but not limited to the use of data mining,
        robots, screen scraping or other similar automated data gathering);
      </Text>
      <Text>
        attempt to undermine the security or integrity of the Application; and
        use or misuse the Application in any way which may impair the
        functionality of the Application.
      </Text>
      <Text>You must ensure that:</Text>
      <Text>
        all usernames, passwords and other credentials used to access
        Application are kept secure and confidential;
      </Text>
      <Text>
        and all data held in the Application and your systems is stored in a
        secure way.{' '}
      </Text>
      <Text>
        You must promptly inform Tax Hitech in writing of any breaches of these
        Terms or our Privacy Policy which impact or may impact us, a Credit
        Provider or other Users.
      </Text>
      <Text>Direct debit</Text>
      <Text>
        This clause 7 applies when you elect to pay your Tax Debt directly by
        the direct debit of your Nominated Bank Account (the option is listed as
        “Pay in full now”, but this labelling may be altered from time to time)
        (Direct Debit Option).
      </Text>
      <Text>
        By confirming your selection of the Direct Debit Option, you authorise
        us to directly withdraw from your Nominated Bank Account an amount which
        will satisfy the entirety of your Tax Debt.
      </Text>
      <Text>
        you have supplied to the financial institution in which the Nominated
        Bank Account is held all authorisations, forms and approvals, and done
        all other things necessary to allow Tax Hitech to directly withdraw from
        your account and pay the amount withdrawn to satisfy your Tax Debt;
      </Text>
      <Text>
        you have sufficient funds in your Nominated Bank Account to satisfy the
        Tax Debt;
      </Text>
      <Text>
        and any payment reference we are given for the payment to be directed to
        the ATO is correct.
      </Text>
      <Text>
        Subject to your satisfaction of clause 7(c), upon your confirmation of
        the Direct Debit Option, Tax Hitech will take reasonable steps to
        promptly withdraw the amount directed and pay this to the ATO.
      </Text>
      <Text>
        For the avoidance of doubt, this clause 7 only applies in circumstances
        where you have confirmed your selection of the Direct Debit Option.
        Filling in the details under the Direct Debit Option is, by itself,
        insufficient.
      </Text>

      <Header>
        <Number>7.</Number> Provision of credit
      </Header>
      <Text>
        This clause 8 applies where you elect to pay your Tax Debt (either in
        whole or in part) through a loan offered by the Credit Provider (the
        option is listed as “Request a loan”, but this labelling may be altered
        from time to time) (Loan Option).
      </Text>
      <Text>
        By confirming your selection of the Loan Option, you authorise Tax
        Hitech to forward to the Credit Provider any Information relevant to
        commencing and assessing an application for the provision of credit to
        you.
      </Text>
      <Text>
        In performing this service, you acknowledge that the Tax Hitech is
        merely providing a platform to facilite the transfer of your Information
        to the Credit Provider, and is not :
      </Text>
      <Text>
        commencing or maintaining a credit application on your behalf;
      </Text>
      <Text>
        supplying the Credit Provider with Information beyond that supplied
        pursuant to clause 8(b) of these Terms, being Information that you
        provide to us in completing the Loan Option on the Application;
      </Text>
      <Text>
        or negotiating on your behalf the terms of any finalised credit contract
        with the Credit Provider (in whatever form) (Credit Contract).
      </Text>
      <Text>
        You expressly acknowledge that you are solely responsible for any
        decision to enter into any Credit Contract and, in performing its
        obligations under these Terms, Tax Hitech:
      </Text>
      <Text>
        is not acting directly or as an intermediary for you to secure the
        provision of credit, and is not:
      </Text>
      <Text>
        <Letter>A.</Letter>
        is not acting directly or as an intermediary for you to secure the
        provision of credit, and is not:
      </Text>
      <Text>
        <Letter>B.</Letter>
        directly and materially assisting you to apply for the Credit Contract,
      </Text>
      <Text>
        or, in any other way, providing credit assistance as defined in section
        8 of the National Consumer Credit Protection Act 2009 (Cth);
      </Text>
      <Text>
        in providing the Services, does not owe you any obligation or duty
        except as set out expressly in these Terms;
      </Text>
      <Text>
        is not a party (in whatever capacity) to any Credit Contract entered
        between yourself and the Credit Provider, and will not be subject to the
        terms or conditions of that agreement;
      </Text>
      <Text>
        does not guarantee the performance of your obligations under the Credit
        Contract;
      </Text>
      <Text>
        and is not responsible for any increase to your Tax Debt or for any
        other loss, damage or liability suffered or incurred by you as a result
        of your decision to use our Services, the Application or enter into a
        Credit Contract.
      </Text>
      <Text>
        If a Credit Contract is finalised, you authorise that any amounts
        payable to you in pursuance to that agreement will instead be paid by
        the Credit Provider into a business trust account held and administered
        by Tax Hitech, for the purpose of collecting and paying the loans sums
        to the ATO to satisfy your Tax Debt. For the avoidance of doubt, you
        authorise any amount received by Tax Hitech into the trust account to be
        paid to the ATO to satisfy your Tax Debt at any time following the time
        the amounts are paid into the trust account.
      </Text>

      <Header>
        <Number>8.</Number> Liability for Tax Debt
      </Header>
      <Text>
        For the avoidance of doubt, at all times you will remain liable for your
        Tax Debt. Tax Hitech is not, and will in no way become, liable for your
        Tax Debt or for any other loss, damage or liability suffered or incurred
        by you as a result of your decision to use our Services, the Application
        or enter into a Credit Contract.
      </Text>

      <Header>
        <Number>9.</Number> Disclaimer
      </Header>
      <Text>
        The Application is provided “as is” and we do not guarantee the
        functionality of the Application.
      </Text>
      <Text>
        Tax Hitech, its directors, officers, employees, contractors,
        sub-contractors, advisors, agents, or representatives:
      </Text>
      <Text>
        make no representations, express or implied, as to the accuracy or
        usefulness of the Information contained in the Application;
      </Text>
      <Text>
        make no representations as to the availability of the Application;
      </Text>
      <Text>
        accept no liability for any use of the Information or reliance placed on
        it;
      </Text>
      <Text>
        and accept no Liability for any interference with or damage to your
        computer, software or data in connection with the Application or its use
        or any site linked to the Application;
      </Text>
      <Text>
        and shall not be liable to you for any loss of use, lost or inaccurate
        data or data corruption, non-compliance with any statutory or legal
        obligation or deadline, lost profits, failure of security mechanisms,
        interruption of business, delays or any direct, indirect, special,
        incidental, reliance or consequential damages of any kind, regardless of
        the form of action, whether in contract, tort (including negligence),
        strict liability or otherwise, even if informed of the possibility of
        such damages in advance.
      </Text>
      <Text>
        Except where otherwise provided for in these Terms or at law, any
        Information which you transmit to the Application is transmitted at your
        own risk. If you become aware of any problems with the security of the
        Application, you must inform us immediately.
      </Text>
      <Text>
        You must take your own precautions to ensure that whatever you select
        for your use is free of viruses or anything else which may interfere
        with or damage the operations of your computer systems
      </Text>
      <Text>
        To the extent permitted by law, Tax Hitech expressly disclaims all
        warranties, express or implied, including without limitation, implied
        warranties of merchantability, fitness for a particular purpose, title,
        compatibility, security, accuracy, or non-infringement.
      </Text>
      <Text>
        All Liability is excluded to the maximum extent permitted by law. To the
        extent that any rights cannot be excluded (including in accordance with
        the Australian Consumer Law), then our Liability will be limited to, at
        our election, the re-supply of the Services or the payment of the cost
        of having the Services supplied again.
      </Text>
      <Text>
        Tax Hitech reserves the right to suspend the Application for the
        purposes of:
      </Text>
      <Text>planned or unplanned downtime for updated and/or maintenance;</Text>
      <Text>
        or for any circumstances beyond Tax Hitech’s reasonable control.
      </Text>
      <Text>Warranties and indemnities</Text>
      <Text>General protections</Text>
      <Text>You warrant and represent that at all times:</Text>
      <Text>
        you have the right and power to enter into these Terms, to perform all
        your obligations under these Terms and to grant those rights set out in
        these Terms;
      </Text>
      <Text>
        and you shall carry out your obligations conscientiously, with due care
        and skill, and in accordance with all applicable laws and regulations.
      </Text>
      <Text>Virus protection</Text>
      <Text>
        You warrant and represent that you will use commercially available
        industry standard virus checking software to ensure that the
        Application, or a User’s experience with the Application, is not
        adversely affected by any type of malicious software, including but not
        limited to:
      </Text>
      <Text>contaminated files;</Text>
      <Text>viruses;</Text>
      <Text>worms;</Text>
      <Text>Trojan horses;</Text>
      <Text>
        and other similar harmful components that could affect or delay the
        functionality of the Application.
      </Text>
      <Text>Indemnity</Text>
      <Text>
        You shall indemnify and hold harmless Tax Hitech and its directors,
        officers, employees, contractors, sub-contractors, advisors, agents, or
        representatives from and against any Liability suffered or incurred by
        any of them relating to your access and use of the Application, the
        Services, the entering into of any Credit Contract, any Information that
        you provide to us or that is collected via the Application, or any
        damage that you may cause to the Application.
      </Text>

      <Header>
        <Number>10.</Number> Privacy
      </Header>
      <Text>
        By agreeing to these Terms, you acknowledge the existence of, and agree
        to, the policies contained in our Privacy Policy.
      </Text>
      <Text>
        Any Personal Information we collect about you via the Application will
        only be used and disclosed by us in accordance with our Privacy Policy
        or these Terms.
      </Text>

      <Header>
        <Number>11.</Number> Intellectual Property Rights
      </Header>
      <Text>
        All Intellectual Property Rights in the relating to the Application
        belong to or are licensed to us.
      </Text>
      <Text>
        To the extent permitted by law, you may not in any form or by any means
        copy, adapt, reproduce (other than for the purpose of viewing the
        Application in your browser), store, modify, distribute, print, upload,
        display, publish or create derivative works from any part of the
        Application or commercialise any information obtained from any part of
        the Application without our prior written consent.
      </Text>

      <Header>
        <Number>12.</Number> Confidentiality and Data Protection
      </Header>
      <Text>Ownership of Confidential Information</Text>
      <Text>
        You acknowledge that all Confidential Information (whether reduced to
        material form or not) disclosed or provided by or on behalf of Tax
        Hitech prior to and after the date of these Terms remains the property
        of Tax Hitech.
      </Text>
      <Text>Confidentiality</Text>
      <Text>You must:</Text>
      <Text>
        use the Confidential Information only for the purposes of performing
        your obligations under these Terms;
      </Text>
      <Text>
        not make use of the Confidential Information to the commercial,
        financial or competitive disadvantage or detriment of Tax Hitech;
      </Text>
      <Text>
        keep the Confidential Information confidential and not disclose it or
        allow it to be disclosed to a third party except:
      </Text>
      <Text>with the prior written approval of Tax Hitech;</Text>
      <Text>
        and to officers, employees, financiers, consultants and advisers of the
        third party who have a need to know (and only to the extent that each
        has a need to know) and are aware, and acknowledge, that the
        Confidential Information must be kept confidential;
      </Text>
      <Text>
        and take or cause to be taken reasonable precautions necessary to
        maintain the secrecy and confidentiality of the Confidential
        Information.
      </Text>
      <Text>Obligation binding</Text>
      <Text>
        The obligations contained in this clause 14 are binding, have worldwide
        effect and will continue indefinitely, subject to:
      </Text>
      <Text>
        the Confidential Information being in the public domain (except as a
        result of a failure to comply with an obligation in this clause 14);
      </Text>
      <Text>
        the Confidential Information being required by law to be disclosed by
        you;
      </Text>
      <Text>
        or the Confidential Information being known and proven to have been
        known by you prior to its disclosure to you in pursuance to these Terms.
      </Text>
      <Text>Data protection</Text>
      <Text>You shall:</Text>
      <Text>
        comply with all applicable Data Protection Legislation when accessing
        and using the Application;
      </Text>
      <Text>
        and take and implement all such technical and organisation security
        procedures and measures necessary or appropriate to preserve the
        security and confidentiality of any Information you control or process
        under or in connection with these Terms.
      </Text>
      <Text>Continuation</Text>
      <Text>
        Your obligations under this clause 14 continue after you have ceased
        accessing or utilising the Application.
      </Text>

      <Header>
        <Number>13.</Number> Miscellaneous
      </Header>
      <Text>
        To the extent not otherwise limited or excluded by these Terms, Tax
        Hitech will not be liable to you for any delay or failure to perform any
        obligation under these Terms if the delay or failure is due to
        unforeseen events which are beyond the reasonable control of Tax Hitech,
        such as a strike, blockade, war, act of terrorism, riot, natural
        disaster, failure or diminishment of power or telecommunications or data
        networks or services, or refusal of a licence by a government agency.
      </Text>
      <Text>
        You acknowledge that this document does not create a relationship of
        employment, trust, agency or partnership with Tax Hitech.
      </Text>
      <Text>
        We may transfer our rights and obligations under these Terms to another
        organisation. Any such transfer will not affect either parties’ rights
        under these Terms.
      </Text>
      <Text>
        If any provision of these Terms is found by any court or body of
        competent jurisdiction to be wholly or partially illegal, invalid, void,
        voidable, unenforceable or unreasonable, it shall be deemed severable to
        the extent that the remaining provisions of these Terms shall continue
        in full force and effect.
      </Text>
      <Text>
        These Terms constitute the entire agreement between you and us on the
        subject matter, and merges and supersedes all other or prior
        understandings, purchase orders, agreements and arrangements.
      </Text>
      <Text>
        A right under these Terms may only be waived in writing by the party
        granting the waiver, and is effective only to the extent specifically
        set out in that waiver. A failure of Tax Hitech to enforce its rights
        under these Terms is not to be treated as a waiver of the benefit of
        those rights.
      </Text>
      <Text>
        The Terms shall be governed by the laws of Queensland, Australia, and
        each party irrevocably submits to the non-exclusive jurisdiction of the
        courts of Queensland and courts competent to hear appeals from those
        court.
      </Text>

      <Header>
        <Number>14.</Number> Defined terms and interpretation
      </Header>
      <Header>
        <NumberSecondary>1.1</NumberSecondary> Defined terms
      </Header>
      <Text>
        <WhiteHeader>Applications</WhiteHeader> has the meaning given in clause
        1(a) of these Terms.
      </Text>
      <Text>
        <WhiteHeader>ATO</WhiteHeader> means the Australian Taxation Office.
      </Text>
      <Text>
        <WhiteHeader>Australian Consumer Law</WhiteHeader> means schedule 2 of
        the Competition and Consumer Act 2010 (Cth), and as enacted in
        Queensland.
      </Text>
      <Text>
        <WhiteHeader>Business Day</WhiteHeader> means a day that is not a
        Saturday, Sunday or public holiday in Brisbane, Queensland.
      </Text>
      <Text>
        <WhiteHeader>Claim</WhiteHeader> includes a claim, notice, demand,
        action, proceeding, litigation, investigation, judgment, damage, loss,
        cost, expense or Liability however arising, whether present, fixed,
        unascertained, immediate, future, actual or contingent, whether based on
        contract, tort, under an indemnity or statute and whether involving a
        third party or a party to this document and where and to the extent the
        context permits, includes all of the associated loss.
      </Text>
      <Text>
        <WhiteHeader>Confidential Information</WhiteHeader> means confidential
        commercial, financial, marketing or technical information, know-how,
        trade secrets, Users’ personal data and other confidential information
        relating to Tax Hitech (including these Terms) in any form or medium
        whether disclosed orally or in writing before or after the date of these
        Terms, together with any reproductions of such information in any form
        or medium or any part of this information.
      </Text>
      <Text>
        <WhiteHeader>Cookies</WhiteHeader> has the meaning given to that term in
        clause 2 of these Terms.
      </Text>
      <Text>
        <WhiteHeader>Corporations Act</WhiteHeader> means the Corporations Act
        2001 (Cth).
      </Text>
      <Text>
        <WhiteHeader>Credit Contract</WhiteHeader> has the meaning given in
        clause 8(c)(iii) of these Terms.
      </Text>
      <Text>
        <WhiteHeader>Credit Provider</WhiteHeader> means the credit provider
        displayed on Tax Hitech’s Application from time to time (which may
        include, for the avoidance of doubt, Ezidebit Pty Ltd ABN 67 096 902
        813). Data Protection Legislation means:
      </Text>
      <Text>
        the Privacy Act 1988 (Cth) and any ancillary rules, guidelines, orders,
        directions, directives, codes of conduct or other instruments made or
        issued under it, as amended from time to time;
      </Text>
      <Text>
        the Australian Privacy Principles (or APPs) contained in schedule 1 of
        the Privacy Act referred to in (a); and
      </Text>
      <Text>
        all other laws, regulations, registered privacy codes, privacy policies
        and contractual terms applicable in the jurisdiction where the API is
        being provided that relate to the processing of personal information.
      </Text>
      <Text>
        <WhiteHeader>Direct Debit Option</WhiteHeader> has the meaning given in
        clause 7(a) of these Terms.
      </Text>
      <Text>
        <WhiteHeader>Information</WhiteHeader> means Personal Information or
        other information that is displayed or can be accessed by Users via the
        Application, whether it is information supplied by Users or information
        independently compiled, generated or collected by Tax Hitech.
      </Text>
      <Text>
        <WhiteHeader>Intellectual Property Rights</WhiteHeader> includes
        patents, utility models, rights to inventions, copyright and
        neighbouring and related rights, trade marks, business names and domain
        names, rights in get-up and trade dress, goodwill and the right to sue
        for passing off, rights in designs, rights in computer software,
        database rights, rights to use, and protect the confidentiality of,
        confidential information (including know-how and trade secrets), and all
        other intellectual property rights, in each case whether registered or
        unregistered and including all applications and rights to apply for and
        be granted, renewals or extensions of, and rights to claim priority
        from, any rights and all similar or equivalent rights or forms of
        protection that subsist or will subsist now or in the future in any part
        of the world.
      </Text>
      <Text>
        <WhiteHeader>Liability</WhiteHeader> means damages, Claims, losses,
        liabilities, costs, fines and expenses (including legal expenses) of any
        kind.
      </Text>
      <Text>
        <WhiteHeader>Loan Option</WhiteHeader> has the meaning given in clause
        8(a) of these Terms.
      </Text>
      <Text>
        <WhiteHeader>Nominated Bank Account</WhiteHeader> means the bank account
        nominated by you in the use of the Application.
      </Text>
      <Text>
        <WhiteHeader>Personal Information</WhiteHeader> has the meaning given to
        that term in our Privacy Policy, available [here].
      </Text>
      <Text>
        <WhiteHeader>Services</WhiteHeader> has the meaning given to that term
        in clause 4(a) of these Terms.
      </Text>
      <Text>
        <WhiteHeader>Tax Debts</WhiteHeader> means any amount owing by you to
        the ATO for any reason, whether or not it is disputed, including any
        applicable late fees, penalties, fines or any other Liability associated
        with the non-payment or late payment of the amount owed.
      </Text>
      <Text>
        <WhiteHeader>Tax Hitech, us</WhiteHeader> or
        <WhiteHeader> we</WhiteHeader> has the meaning given in clause 1(a) of
        these Term.
      </Text>
      <Text>
        <WhiteHeader>Terms</WhiteHeader> has the meaning given in clause 1(a) of
        these Terms.
      </Text>
      <Text>
        <WhiteHeader>Users</WhiteHeader> refers to visitors, customers and
        others who access the Application whether on their own behalf or on
        behalf of another person.
      </Text>
      <Text>Interpretation</Text>
      <Text>In this document, unless the context requires otherwise:</Text>
      <Text>the singular includes its plural and vice versa;</Text>
      <Text>words denoting any gender include all genders;</Text>
      <Text>
        headings are for convenience only and do not affect interpretation;
      </Text>
      <Text>
        words such as “including” or “for example” do not limit the meaning of
        the words preceding them;
      </Text>
      <Text>
        an obligation or liability assumed by, or a right conferred on, two or
        more parties binds or benefits all of them jointly and each of them
        severally;
      </Text>
      <Text>
        nothing in this document is to be interpreted against a party solely on
        the ground that the party or its advisers drafted it; and
      </Text>
      <Text>a reference to:</Text>
      <Text>
        a person includes a corporation, trust, partnership, unincorporated body
        or other entity, whether or not it comprises a separate legal entity;
      </Text>
      <Text>
        a party to this document or another document includes that party’s
        successors, permitted substitutes or permitted assigns;
      </Text>
      <Text>a particular time is to that time in Brisbane, Queensland;</Text>
      <Text>
        any agreement (including this document) or document is to the agreement
        or document as amended, supplemented, novated or replaced from time to
        time;
      </Text>
      <Text>
        a clause, paragraph, schedule or annexure is to a clause, paragraph,
        schedule or annexure in or to this document;
      </Text>
      <Text>
        this document includes any schedules and annexures to this document;
      </Text>
      <Text>
        writing includes any method of representing or reproducing words,
        figures, drawings or symbols in a visible or tangible form;
      </Text>
      <Text>dollars or $ is to Australian currency;</Text>
      <Text>
        legislation (including subordinate legislation) is to that legislation
        as amended, re-enacted or replaced, and includes any subordinate
        legislation issued under it; and
      </Text>
      <Text>
        a provision within legislation (or subordinate legislation) is to that
        provision as amended or replaced.
      </Text>
      <Text>Business Days</Text>
      <Text>
        If the day on or by which a party must do something under this document
        is not a Business Day, the party must do it on or by the next Business
        Day.
      </Text>
      <Text>Consents or approvals</Text>
      <Text>
        Unless expressed to the contrary in this document, if the doing of any
        act, matter or thing under this document is dependent on the consent or
        approval of a party or is within the discretion of a party, the consent
        or approval may be given or the discretion may be exercised
        conditionally or unconditionally or withheld by the party in its
        absolute discretion.
      </Text>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%;
  font-family: adonis-web;
  height: 100%;
  background-color: #113958;
  padding: 50px 150px;
  overflow: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    background: transparent;
  }
  @media (max-width: 900px) {
    padding: 50px 50px;
  }
  @media (max-width: 450px) {
    padding: 30px 30px;
  }
`;

const Text = styled.p`
  font-size: calc((1 - 1) * 1.2vw + 1rem);
  color: white;
  font-weight: 400;
  color: #d3d3d3;
  padding: 5px 0;
`;

const Header = styled.h3`
  color: white;
  font-weight: 400;
  font-size: calc((1.6 - 1) * 1.2vw + 1rem);
  padding: 30px 2px;
`;

const Number = styled.div`
  color: white;
  display: inline-block;
  font-weight: 400;
  padding-right: 10px;
`;

const NumberSecondary = styled.div`
  color: white;
  display: inline-block;
  padding: 0;
  font-size: calc((1.6 - 1) * 1.2vw + 1rem);
`;

const WhiteHeader = styled.span`
  font-weight: bold;
  color: white;
`;

const Letter = styled.div`
  color: white;
  display: inline-block;
  padding-right: 10px;
  padding-left: 15px;
`;
