/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {LogoSVG} from 'src/assets';
import {Link, useHistory} from 'react-router-dom';
// import {AuthProps} from 'src/store/auth/types';

interface HeaderProps {}

export const HeaderMain = React.memo<HeaderProps>(() => {
  let history = useHistory();

  history.listen((location) => {
    setLocation(location.pathname);
  });

  useEffect(() => {
    setLocation(history.location.pathname);
  }, [history.location]);

  const [location, setLocation] = useState<any>('');

  return (
    <Wrapper>
      <Top>
        <BlockLogo>
          <LogoCont>
            <LogoSVG />
          </LogoCont>
          <Text>PayMyTax</Text>
        </BlockLogo>
        <Block>
          <Link
            to="/faq"
            target="_blank"
            style={{
              fontSize: 17,
              paddingRight: 10,
              fontWeight: 400,
              color: 'black',
              textDecoration: location === '/faq' ? 'underline' : 'none',
            }}>
            FAQ
          </Link>
          <Link
            to="/contact-us"
            style={{
              fontSize: 17,
              paddingLeft: 10,
              fontWeight: 400,
              color: 'black',
              textDecoration: location === '/contact-us' ? 'underline' : 'none',
            }}>
            Contact us
          </Link>
        </Block>
      </Top>
      <Bottom>
        <TextMain>
          PayMyTax is like AfterPay for business tax payments.
        </TextMain>
        <TextMain>
          Payments to the ATO should be easy, flexible and stress free.
        </TextMain>
      </Bottom>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 70px;
`;

const Top = styled.div`
  width: 100%;
  height: 41%;
  background-color: white;
  padding: 0 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 454px) {
    flex-direction: row;
    justify-content: space-around;
    padding: 0;
  }
`;

const Bottom = styled.div`
  width: 100%;
  height: 60%;
  background-color: #113958;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const BlockLogo = styled.div`
  width: 100px;
  height: 95px;
  display: flex;
  flex-direction: column;
  align-items: center;
  paddin-bottom: 5px;
`;
const Block = styled.div`
display: 'block';
  width: 100p;
  height: 20px;
`;

const LogoCont = styled.div`
  height: 60px;
  width: 60px;
  margin-bottom: 15px;
`;

const Text = styled.p`
  font-size: 18px;
  margin-top: 8px;
  line-height: 21px;
  font-weight: bold;
  color: #103b58;
  @media (max-width: 454px) {
    font-size: 16px;
  }
`;

const TextMain = styled.div`
  height: 40px;
  font-size: 25px;
  font-weight: 400;
  text-align: center;
  color: white;
  @media (max-width: 800px) {
    font-size: 16px;
  }
`;
