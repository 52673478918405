import React from 'react';
import styled from 'styled-components';
import {useHistory, useLocation} from 'react-router-dom';

import {Logo, Button, PageWrapper} from 'src/components';
import {Theme} from 'src/theme';
import {useSelector} from 'react-redux';
//import {getPayment} from 'src/store/payments/actions';
//import {useAction} from 'src/utils/hooks';
import {HeaderMain} from 'src/components';
import {RootState} from 'src/store/types';
import { NewPayment } from '../new-payment';

export const Home = React.memo(() => {
  const history = useHistory();
  let location = useLocation();
  //const paymentAction = useAction(getPayment.request);
  //const {payment} = useSelector((state: RootState) => state.payments);
  // const {paymentHistory} = useSelector((state: RootState) => state.payments);
  
  const token = useSelector((state: RootState) => state.auth.token);
   

  return (
    <Wrapper>
      {token !== "" && location.pathname !== "/" ? <WrapperContent>
        {/* <Header isLogo={false} onDisplay={() => {setDisplay(!display)}}></Header> */}
        <Content>
            <NewPayment/>
        </Content>
      </WrapperContent>
      :<WrapperContent>
        <HeaderWraper>
          <HeaderMain />
        </HeaderWraper>
        <PageWrapper alignItems="center">
          <Logo />
          {/* <Text>
        Please log in if you have crated an account before, or ask your
        accountant to visit{' '}
        <Link href="https://pay-my-tax-web-dev.appelloproject.xyz/login">
          paymytax.co
        </Link>{' '}
        and create an account
      </Text> */}
          <Button text="Log in" onClick={() => history.push('/login')} />
          <Text>
            If you don’t have an account, please contact us at{' '}
            <Link href="mailto:support@paymytax.com.au">
              support@paymytax.com.au
            </Link>
          </Text>
        </PageWrapper>
      </WrapperContent>}
    </Wrapper>
  );
});

// const PageHeader = styled.h2`
//   font-size: 28px;
//   font-weight: 600;
//   line-height: 21px;
//   color: ${Theme.colors.black2};

//   @media (max-width: 335px) {
//     font-size: 26px;
//   }
// `;

const Wrapper = styled.div`
  width: 100%;
`;

// const Payment = styled.div`
// margin: auto;
//   width: 80%;
// `;

const Content = styled.div`
margin: auto;
  margin-top: 18px;
  width: 80%;
  min-height: 82vh;
  height: '100%';
`;

const WrapperContent = styled.div`
display: contents;
  width: 100%;
  height: 50vh;
`;

const HeaderWraper = styled.div`
  width: 100%;
  height: 50vh;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 21px;
  margin: 16px 0 28px 0;
  color: ${Theme.colors.black3};
  text-align: center;
  padding: 0 6px;
`;

const Link = styled.a`
  font-size: 14px;
  line-height: 21px;
  text-decoration: none;
  color: ${Theme.colors.purple};
`;
