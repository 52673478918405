import api from './api';

export class Auth {
  static LoginReq(data: {abn: string;}) {
    return api.post('business/signin', data);
  }

  static SendCodeReq(data: {abn: string; code: string}) {
    return api.post('business/verification', data);
  }

  static RefreshTokenReq(data: {refresh: string}) {
    return api.post<{access: string}>('auth/token/refresh', data);
  }
}
