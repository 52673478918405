import React from 'react';
import ReactCodeInput from 'react-code-input';

import {Theme} from 'src/theme';

interface CodeInputProps {
  onChange(val: string): void;
  value: string;
}

const screenWidth = window.screen.width;

export const CodeInput = React.memo<CodeInputProps>(({onChange, value}) => {
  return (
    <ReactCodeInput
      className="test"
      value={value}
      type="text"
      fields={4}
      name="code"
      inputMode="numeric"
      onChange={onChange}
      inputStyle={{
        fontSize: '27px',
        lineHeight: '27px',
        fontWeight: 400,
        background: Theme.colors.white,
        borderRadius: '3px',
        width: screenWidth <= 335 ? '54px' : '60px',
        height: screenWidth <= 335 ? '73px' : '79px',
        boxSizing: 'border-box',
        border: '1px solid #D9D9D9',
        margin: '0 6.5px',
        textAlign: 'center',
      }}
    />
  );
});
