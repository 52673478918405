import {ActionType, getType} from 'deox';
import {SagaIterator} from 'redux-saga';
import {call, put, takeEvery} from 'redux-saga/effects';

import {Payments} from 'src/services/payments';
import {
  getPayment,
  getLoanPayment,
  getActivePayments,
  addLoanPayment,
  getPaymentHistory,
  getCompany,
  addProofFile,
  deleteProofFile,
  getXeroAuthorizationLink,
  addPaymentScheduler,
  getPaymentById,
} from './actions';

function* getPaymentSaga(): SagaIterator {
  try {
    const res = yield call(Payments.getPayment);
    console.log("getPaymentSaga", res.data);
    res.status === 200
      ? yield put(getPayment.success(res.data))
      : yield put(getPayment.fail());
  } catch (e) {
    yield put(getPayment.fail());
  }
}

function* getPaymentByIdSaga({
  payload,
}: ActionType<typeof getPaymentById.request>): SagaIterator {
  try {
    const res = yield call(Payments.getPaymentById, payload);
    console.log("payload", payload);
    console.log("result", res);
    res.status === 200
      ? yield put (getPaymentById.success(res.data))
      : yield put (getPaymentById.fail());
  } catch (e) {
    yield put(getPaymentById.fail());
  }
}

function* getLoanPaymentSaga({
  payload,
}: ActionType<typeof getLoanPayment.request>): SagaIterator {
  try {
    const res = yield call(Payments.getLoanPayment, payload);
    res.status === 200
      ? yield put(getLoanPayment.success(res.data))
      : yield put(getLoanPayment.fail());
  } catch (e) {
    yield put(getLoanPayment.fail());
  }
}

function* addLoanPaymentSaga({
  payload,
}: ActionType<typeof addLoanPayment.request>): SagaIterator {
  try {
    const res = yield call(Payments.addLoanPayment, payload);
    res.status === 200
      ? yield put(addLoanPayment.success(res.data))
      : yield put(addLoanPayment.fail());
  } catch (e) {
    yield put(addLoanPayment.fail());
  }
}

function* getActivePaymentsSaga() : SagaIterator {
  try {
    const res = yield call(Payments.getActivePayments);
    res.status === 200 
      ? yield put(getActivePayments.success(res.data))
      : yield put(getActivePayments.fail())
  } catch (error) {
    yield put(getActivePayments.fail())
  }
}

function* getPaymentHistorySaga(): SagaIterator {
  try {
    const res = yield call(Payments.getPaymentHistory);
    res.status === 200
      ? yield put(getPaymentHistory.success(res.data))
      : yield put(getPaymentHistory.fail());
  } catch (e) {
    yield put(getPayment.fail());
  }
}

function* getCompanySaga(): SagaIterator {
  try {
    const res = yield call(Payments.getCompany);
    res.status === 200
      ? yield put(getCompany.success(res.data.company_name))
      : yield put(getCompany.fail());
  } catch (e) {
    yield put(getPayment.fail());
  }
}

function* addProofFileSaga({
  payload,
  }: ActionType<typeof addProofFile.request>): SagaIterator {
  try {
    const res = yield call(Payments.addProofFile, payload);
    res.status === 201
      ? yield put(addProofFile.success(res.data))
      : yield put(addProofFile.fail());
  } catch (e) {
    yield put(addProofFile.fail());
  }
}

function* deleteProofFileSaga({
  payload,
  }: ActionType<typeof deleteProofFile.request>): SagaIterator {
  try {
    // const res = yield call(Payments.deleteProofFile, payload);
    // console.log(res,'resres')
    // res.status === 204
      // ? yield put(deleteProofFile.success(res.data))
      // : yield put(deleteProofFile.fail());
    yield put(deleteProofFile.success(2))

  } catch (e) {
    yield put(deleteProofFile.fail());
  }
}

function* getXeroAuthorizationLinkSaga(): SagaIterator {
  try {
    const res = yield call(Payments.getXeroAuth);
    res.status === 200
      ? yield put(getXeroAuthorizationLink.success(res.data.xero_auth))
      : yield put(getXeroAuthorizationLink.fail());
  } catch (e) {
    yield put(getPayment.fail());
  }
}

function* addPaymentSchedulerSaga({
  payload,
}: ActionType<typeof addPaymentScheduler.request>): SagaIterator {
  try {
    const res = yield call(Payments.addPaymentScheduler, payload);
    res.status === 200
      ? yield put(addPaymentScheduler.success(res.data))
      : yield put(addPaymentScheduler.fail());
  } catch (e) {
    yield put(addPaymentScheduler.fail());
  }
}

export function* watchPayment(): SagaIterator {
  yield takeEvery(getType(getPayment.request), getPaymentSaga);
  yield takeEvery(getType(getLoanPayment.request), getLoanPaymentSaga);
  yield takeEvery(getType(getActivePayments.request), getActivePaymentsSaga);
  yield takeEvery(getType(getPaymentById.request), getPaymentByIdSaga);
  yield takeEvery(getType(addLoanPayment.request), addLoanPaymentSaga);
  yield takeEvery(getType(getPaymentHistory.request), getPaymentHistorySaga);
  yield takeEvery(getType(getCompany.request), getCompanySaga);
  yield takeEvery(getType(addProofFile.request), addProofFileSaga);
  yield takeEvery(getType(deleteProofFile.request), deleteProofFileSaga);
  yield takeEvery(
    getType(getXeroAuthorizationLink.request),
    getXeroAuthorizationLinkSaga,
  );
  yield takeEvery(
    getType(addPaymentScheduler.request),
    addPaymentSchedulerSaga,
  );
}
