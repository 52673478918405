import React from 'react';
import styled from 'styled-components';

interface PageWrapperProps {
  alignItems?: string;
  justifyContent?: string;
}

export const PageWrapper: React.FC<PageWrapperProps> = ({
  children,
  alignItems,
  justifyContent,
}) => {
  return ( 
    <Wrapper alignItems={alignItems} justifyContent={justifyContent}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1020px;
  width: 100%;
  margin: auto; 
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: ${(props: PageWrapperProps) =>
    props.alignItems ? props.alignItems : 'flex-start'};
  justify-content: ${(props: PageWrapperProps) =>
    props.justifyContent || 'flex-start'};
  padding-bottom: 10px;
`;
