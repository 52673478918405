import {ActionType, getType} from 'deox';
import {SagaIterator} from 'redux-saga';
import {call, put, takeEvery} from 'redux-saga/effects';
import {Auth} from 'src/services';

import {resetStore} from '../app/actions';
import {login, sendCode, logOut} from './actions';

function* loginSaga({
  payload: {phone},
}: ActionType<typeof login.request>): SagaIterator {
  try {
    const res = yield call(Auth.LoginReq, {abn: phone});
    if (res.status === 200) {
       
      yield put(login.success(res.data));
      yield put(sendCode.fail([]));
    } else {
      yield put(
        login.fail(
          (!!res.data.abn && res.data.abn) || (!!res.data && [res.data]),
        ),
      );
      yield put(sendCode.fail([res.data]));
    }
  } catch (e) {
    yield put(login.fail([]));
  }
}

function* sendCodeSaga({
  payload: {phone, code},
}: ActionType<typeof sendCode.request>): SagaIterator {
  try {
    const res = yield call(Auth.SendCodeReq, {abn: phone, code});
    if (res.status === 200) {
       
      yield put(
        sendCode.success({token: res.data.access, refresh: res.data.refresh, payments: res.data.payments}),
      );
      
    } else {
      yield put(sendCode.fail(res.data.code));
    }
  } catch (e) {
    yield put(sendCode.fail([]));
  }
}

function* signOutSaga(): SagaIterator {
  yield put(resetStore());
}

export function* watchAuth(): SagaIterator {
  yield takeEvery(getType(login.request), loginSaga);
  yield takeEvery(getType(sendCode.request), sendCodeSaga);
  yield takeEvery(getType(logOut), signOutSaga);
}
