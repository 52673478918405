import React from 'react';
import styled from 'styled-components';

import {Theme} from 'src/theme';

interface PayLaterProps {
  amount: string;
  term: string;
}

interface TextProps {
  isPurple?: boolean;
}

export const PayLater = React.memo<PayLaterProps>(({amount, term}) => {
  return (
    <Wrapper>
      <Text>Pay later</Text>
      {amount !== 'NaN' ? <AmountText>${amount}</AmountText> : <AmountText>$0.00</AmountText>}
      <Text isPurple>{term}</Text>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  background-color: #eff1f5;
  width: 100%;
  height: 130px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 17px 0;
`;

const Text = styled.p`
  font-size: 14px;
  color: ${(props: TextProps) =>
    props.isPurple ? Theme.colors.purple : Theme.colors.black3};
`;

const AmountText = styled.p`
  font-size: 33px;
  font-weight: 600;
  color: ${Theme.colors.purple};
`;
