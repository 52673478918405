/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {
  PageWrapper,
  PaymentInfo,
  SliderInput,
  Button,
  PaymentInfoCharacteristic,
  Header,
} from 'src/components';
import {PayLater} from './components';
import {Theme} from 'src/theme';
import {RootState} from 'src/store/types';
import {useAction} from 'src/utils/hooks';
import {setPayNowAmount, setPayInfo} from 'src/store/payments/actions';
import {moneyAmountFormat, getFormatedSliderValue} from 'src/helpers';
import {getXeroAuthorizationLink} from 'src/store/payments/actions';
import {PaymentIcon} from 'src/assets';
import {getActivePayments} from 'src/store/payments/actions';
import {getPaymentById} from 'src/store/payments/actions';
// import { AsyncStatus } from 'src/types';

export const NewPayment = React.memo(() => {
  // const {payment} = useSelector((state: RootState) => state.payments);
  const {abn} = useSelector((state: RootState) => state.auth);
  const {id} = useSelector((state: RootState) => state.payments);
  const getPaymentByIdStatus = useAction(getPaymentById.request);
  const {activePayment} = useSelector((state: RootState) => state.payments);
  const {activePayments} = useSelector((state: RootState) => state.payments);
  const activePaymentsAction = useAction(getActivePayments.request);
  // const {getPaymentReqStat} = useSelector(
  //   (state: RootState) => state.payments,
  // );

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const totalAmount = +(activePayment?.is_active
    ? activePayment?.total_amount
    : activePayments[0]?.total_amount);
  // const item = activePayments.shift()
  // const totalAmount = +activePayments[0]?.total_amount
  const [color, setColor] = useState(
    totalAmount > 5000.0 ? Theme.colors.purple : Theme.colors.gray,
  );
  const maxAlovedValue = totalAmount >= 5000.0 ? totalAmount : 0;

  const getXeroAuthorizationLinkAction = useAction(
    getXeroAuthorizationLink.request,
  );

  const [disabled, setDisabled] = useState(false);

  const [sliderValue, setSliderValue] = useState<number>(
    totalAmount >= 5000.0 ? 0 : totalAmount,
  );
  const [display, setDisplay] = useState(true);

  const setPayNowAmountAction = useAction(setPayNowAmount.success);
  const setPayInfoAction = useAction(setPayInfo);

  const history = useHistory();

  const sortingPayment = [];

  sortingPayment.push(...activePayments);
  sortingPayment.sort((a, b) => +b.id - +a.id);

  const laterMoney = (totalAmount - sliderValue) * 0.075;

  const reloadCount = Number(sessionStorage.getItem('reloadCount')) || 0;

  useEffect(() => {
    console.log(totalAmount, 'totalAmount');
    totalAmount < 5000.0 && setDisabled(true);
  }, []);

  useEffect(() => {
    if (reloadCount < 1) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      setTimeout(() => window.location.reload(), 1000);
    } else {
      sessionStorage.removeItem('reloadCount');
    }
    // eslint-disable-next-line
  }, [setTimeout]);

  useEffect(() => {
    if (activePayment.is_active) {
      setTimeout(() => setLoading(false), 5500);
      if (!totalAmount && activePayment.total_amount !== '0.00')
        setTimeout(() => window.location.reload(), 2000);
      console.log('1');
    } else {
      setTimeout(() => setLoading(false), 3000);
      console.log('3');
      getPaymentByIdStatus(id);
      if (!totalAmount && activePayment.total_amount && activePayments.length)
        setTimeout(() => window.location.reload(), 2000);
    }
  }, [id]);

  useEffect(() => {
    activePaymentsAction();
    setSliderValue(totalAmount >= 5000.0 ? 0 : totalAmount);
    setColor(totalAmount >= 5000.0 ? Theme.colors.purple : Theme.colors.gray);
  }, [id, activePayment]);

  const loanRequestClick = () => {
    if (totalAmount - sliderValue > 50000) {
      getXeroAuthorizationLinkAction();
    }

    const payNowAmount = sliderValue;
    const payLaterAmount = totalAmount - payNowAmount;

    console.log('pay later amount', payLaterAmount);

    setPayInfoAction({totalAmount, payNowAmount, payLaterAmount});

    if (payLaterAmount >= 5000.0 || payLaterAmount === 0) {
      setError(false);
      setPayNowAmountAction(sliderValue);
      // setPayInfoAction({totalAmount, payNowAmount, payLaterAmount});

      sliderValue === 0
        ? // ? history.push('/new-payment/illion')
          setPayInfoAction({totalAmount, payNowAmount, payLaterAmount})
        : history.push('/new-payment/payment-schedule');
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    const payNowAmount = sliderValue;
    const payLaterAmount = totalAmount - payNowAmount;
    setPayInfoAction({totalAmount, payNowAmount, payLaterAmount});
  }, [sliderValue]);

  const onSliderValueChange = (resp: number) => {
    console.log(resp, 'resp');
    if (resp >= maxAlovedValue) {
      if (maxAlovedValue === 0 && totalAmount < 5000.0) {
        console.log('1');
        setSliderValue(totalAmount);
        setColor(Theme.colors.gray);
      } else {
        console.log('2');
        setSliderValue(maxAlovedValue);
      }
    } else {
      setSliderValue(getFormatedSliderValue(resp, totalAmount));
    }
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <Fragment>
      <Wrapper>
        <Header
          isLogo={false}
          onDisplay={() => {
            setDisplay(!display);
          }}></Header>
        {display && (
          <Content>
            {activePayments.length && activePayment.is_active ? (
              <PageWrapper>
                <HeaderText>New payment</HeaderText>
                <SectionTitle>Payment info</SectionTitle>
                <PaymentInfo
                  typeOfActivity={
                    activePayment.is_active
                      ? activePayment.tax_company.tax_name
                      : sortingPayment[0].tax_company.tax_name
                  }
                  period={
                    activePayment.is_active
                      ? activePayment.period
                      : sortingPayment[0].period
                  }
                  dueDate={
                    activePayment.is_active
                      ? activePayment.due_date
                      : sortingPayment[0].due_date
                  }
                  totalAmountDue={`$${moneyAmountFormat(
                    activePayment.is_active
                      ? activePayment.total_amount
                      : sortingPayment[0].total_amount,
                  )}`}
                />
                <SliderInput
                  disabled={disabled}
                  title="Pay now"
                  min={0}
                  color={color}
                  max={isNaN(totalAmount) ? 0.0 : totalAmount}
                  value={isNaN(sliderValue) ? 0.0 : sliderValue}
                  onChange={onSliderValueChange}
                  maxAlovedValue={maxAlovedValue}
                />
                <PayLater
                  amount={moneyAmountFormat(
                    (
                      Number(totalAmount - sliderValue + laterMoney) / 13
                    ).toFixed(2),
                  )}
                  term="weekly"
                />
                <PaymentInfoCharacteristicWrapper>
                  <PaymentInfoCharacteristic
                    title="Payment terms"
                    value="13 weeks"
                  />
                  <PaymentInfoCharacteristic
                    title="Pay now amount"
                    value={`$${moneyAmountFormat(sliderValue.toFixed(2))}`}
                  />
                  <PaymentInfoCharacteristic
                    title={
                      totalAmount >= 5000.0
                        ? 'Pay later amount'
                        : 'The loan facility is available only for payments above $5000'
                    }
                    value={
                      totalAmount >= 5000.0
                        ? `$${moneyAmountFormat(
                            (totalAmount - sliderValue + laterMoney).toFixed(2),
                          )}`
                        : ''
                    }
                  />
                  <PaymentInfoCharacteristic
                    title="The “Pay later” amount is a general guide only and does not constitute an offer or credit approval"
                    value=""
                  />
                </PaymentInfoCharacteristicWrapper>
                {error && (
                  <Content>
                    <ErrorMessage>
                      The minimum loan amount is $5,000
                    </ErrorMessage>
                    <ErrorMessage>
                      If you don’t wish to apply for a loan, please set Pay Now
                      to{' '}
                      {formatter
                        .format(+activePayment.total_amount)
                        .replace('.00', '')}
                    </ErrorMessage>
                  </Content>
                )}
                {sliderValue ? (
                  <Button text="Next" onClick={loanRequestClick} />
                ) : (
                  <Link1
                    // eslint-disable-next-line
                    href={`https://scv.bankstatements.com.au/QDWC-PMT${abn}`}
                    target="_self">
                    Next
                  </Link1>
                )}

                {/* <a>
                <Button text="Next" onClick={loanRequestClick} />
                </a> */}
                <PolicyText>
                  By Continuing you agree to our 
                  <Link
                    href="https://www.paymytax.com.au/terms-conditions"
                    target="_blank">
                    Terms & Conditions
                  </Link>
                   and 
                  <Link
                    href="https://www.paymytax.com.au/privacy-policy"
                    target="_blank">
                    Privacy Policy
                  </Link>
                  , you also agree to have your credit report pulled by Lumi if
                  required.
                </PolicyText>
              </PageWrapper>
            ) : (
              <PageWrapper>
                <ContentWrapper>
                  <Round>
                    <PaymentIcon />
                  </Round>
                  {loading ? (
                    <AppText> {'Loading'}</AppText>
                  ) : (
                    <AppText> {'This payment has already been paid'}</AppText>
                  )}
                </ContentWrapper>
              </PageWrapper>
            )}
          </Content>
        )}
      </Wrapper>
    </Fragment>
  );
});

const Content = styled.div`
  width: 100%;
`;

const HeaderText = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  color: ${Theme.colors.black};
  margin: 16px 0 10px 0;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 16px;
  font-weight: 600;
  margin: 0px 0 5px 0;
`;

const SectionTitle = styled.p`
  font-size: 13px;
  line-height: 18px;
  font-weight: 400px;
  color: ${Theme.colors.muted};
  margin-bottom: 4px;
`;

const PaymentInfoCharacteristicWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  margin: 18px 0 25px 0;
`;

const Round = styled.div`
  width: 160px;
  height: 160px;
  background-color: #fafafa;
  border-radius: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  max-width: 1020px;
  width: 100%;
  height: 85vh;
  margin: auto;
`;

const AppText = styled.div`
  margin-top: 30px;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  line-height: 160%;
  color: black;
`;

const PolicyText = styled.p`
  width: 100%;
  text-align: center;
  font-sise: 13px
  line-height: 13px;
  font-weight: 900;
  margin-top: 20px;
`;

const Link = styled.a`
  text-decoration: none;
  color: ${Theme.colors.purple};
`;

const Link1 = styled.a`
  text-decoration: none;
  color: ${Theme.colors.purple};
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  color: #fff;
  align-items: center;
  font-size: 14px;
  background-color: ${Theme.colors.purple};
`;

const Wrapper = styled.div`
  height: 100%;
  margin-bottom: 15px;
`;
