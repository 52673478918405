import axios, {
    AxiosError,
    AxiosInstance,
    AxiosPromise,
    AxiosRequestConfig,
  } from 'axios';
  // import {sendCode} from 'src/store/auth/actions';
  import {config} from '../config';
  import store from '../store';
  import {Auth} from './auth';
  
  export class Api {
    static instance: Api;
    axiosInstance: AxiosInstance;
  
    constructor() {
      const header: {[key: string]: string} = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
  
      const refreshToken = async (error: AxiosError) => {
        if (error.response?.status === 401) {
          const refresh = store.getState().auth.refresh;
          const res = await Auth.RefreshTokenReq({refresh});
          //store.dispatch(sendCode.success({token: res.data.access, refresh, payments: []}));
          this.axiosInstance.defaults.headers.Authorization = `Bearer ${res.data.access}`;
          error.config.headers.Authorization = `Bearer ${res.data.access}`;
          return this.axiosInstance(error.config);
        }
        return error.response;
      };
  
      if (store.getState().auth.token) {
        header.Authorization = `Bearer ${store.getState().auth.token}`;
      }
  
      this.axiosInstance = axios.create({
        timeout: 10000,
        baseURL: config.BASE_URL,
        headers: header,
      });
  
      this.axiosInstance.interceptors.response.use((response) => {
        return response;
      }, refreshToken);
    }
  
    static getInstance(): Api {
      if (!Api.instance) {
        Api.instance = new Api();
      }
      return Api.instance;
    }
  
    static getAxios(): AxiosInstance {
      return Api.getInstance().axiosInstance;
    }
  
    static setAuthToken(token: string): void {
      Api.getAxios().defaults.headers.Authorization = `Bearer ${token}`;
    }
  
    static getAuthToken(): string {
      return Api.getAxios().defaults.headers.Authorization;
    }
  
    static clearAuthToken(): void {
      Api.getAxios().defaults.headers.Authorization = null;
    }
  
    static get<T>(
      url: string,
      params: object = {},
      config: AxiosRequestConfig = {},
    ): AxiosPromise<T> {
      return Api.getAxios().get(url, {params, ...config});
    }
    static delete<T>(
      url: string,
      params: object = {},
      config: AxiosRequestConfig = {},
    ): AxiosPromise<T> {
      return Api.getAxios().delete(url, {params, ...config});
    }
  
    static post<T>(
      url: string,
      data?: object,
      config?: AxiosRequestConfig,
    ): AxiosPromise<T> {
      return Api.getAxios().post(url, data, config);
    }
  
    static put<T>(
      url: string,
      data?: object,
      config?: AxiosRequestConfig,
    ): AxiosPromise<T> {
      return Api.getAxios().put(url, data, config);
    }
  
    static patch<T>(
      url: string,
      data?: object,
      config?: AxiosRequestConfig,
    ): AxiosPromise<T> {
      return Api.getAxios().patch(url, data, config);
    }
  }
  
  export default Api;
  