import {createReducer} from 'deox';
import {produce} from 'immer';

import {AsyncStatus} from 'src/types';
import {loginIllion, getInstitutions} from './actions';
import {IllionReducerProps} from './types';

const initialState: IllionReducerProps = {
  institutions: [],
  loginIllionReqStat: AsyncStatus.NONE,
  getInstitutionsReqStat: AsyncStatus.NONE,
};

export const illionReducer = createReducer(initialState, (handleAction) => [
  // ILLION LOGIN
  handleAction(loginIllion.request, (state) =>
    produce(state, (draft) => {
      draft.loginIllionReqStat = AsyncStatus.LOADING;
    }),
  ),

  handleAction(loginIllion.success, (state) =>
    produce(state, (draft) => {
      draft.loginIllionReqStat = AsyncStatus.SUCCESS;
    }),
  ),

  handleAction(loginIllion.fail, (state) =>
    produce(state, (draft) => {
      draft.loginIllionReqStat = AsyncStatus.FAIL;
    }),
  ),

  // GET INSTITUTIONS
  handleAction(getInstitutions.request, (state) =>
    produce(state, (draft) => {
      draft.getInstitutionsReqStat = AsyncStatus.LOADING;
    }),
  ),

  handleAction(getInstitutions.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.getInstitutionsReqStat = AsyncStatus.SUCCESS;
      draft.institutions = payload;
    }),
  ),

  handleAction(getInstitutions.fail, (state) =>
    produce(state, (draft) => {
      draft.getInstitutionsReqStat = AsyncStatus.FAIL;
    }),
  ),
]);
