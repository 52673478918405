import {createReducer} from 'deox';
import {produce} from 'immer';

import {getBankInfo, addBankInfo, addDirectPayment} from './actions';
import {EzidebitReducerProps} from './types';
import {AsyncStatus} from 'src/types';

const initialState: EzidebitReducerProps = {
  bankInfo: {
    account_name: '',
    bsb: '',
    account_number: '',
    customer_id: 0,
  },
  directPayment: {
    payment: 0,
    amount: '',
  },
  getBankInfoReqStat: AsyncStatus.NONE,
  addbankInfoReqStat: AsyncStatus.NONE,
  addDirectPaymentReqStat: AsyncStatus.NONE,
  error: '',
};

export const EzidebitReducer = createReducer(initialState, (handleAction) => [
  // GET BANK INFO
  handleAction(getBankInfo.request, (state) =>
    produce(state, (draft) => {
      draft.getBankInfoReqStat = AsyncStatus.LOADING;
    }),
  ),
  handleAction(getBankInfo.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.getBankInfoReqStat = AsyncStatus.SUCCESS;
      draft.bankInfo = payload;
    }),
  ),
  handleAction(getBankInfo.fail, (state) =>
    produce(state, (draft) => {
      draft.getBankInfoReqStat = AsyncStatus.FAIL;
    }),
  ),

  // ADD BANK INFO
  handleAction(addBankInfo.request, (state) =>
    produce(state, (draft) => {
      draft.addbankInfoReqStat = AsyncStatus.LOADING;
    }),
  ),
  handleAction(addBankInfo.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.addbankInfoReqStat = AsyncStatus.SUCCESS;
      draft.bankInfo = payload;
      draft.error = '';
    }),
  ),
  handleAction(addBankInfo.fail, (state, {payload}) =>
    produce(state, (draft) => {
      draft.addbankInfoReqStat = AsyncStatus.FAIL;
      draft.error = "Could not find a customer with the provided details.";
    }),
  ),

  // ADD DIRECT PAYMENT
  handleAction(addDirectPayment.request, (state) =>
    produce(state, (draft) => {
      draft.addDirectPaymentReqStat = AsyncStatus.LOADING;
    }),
  ),
  handleAction(addDirectPayment.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.addDirectPaymentReqStat = AsyncStatus.SUCCESS;
      draft.directPayment = payload;
    }),
  ),
  handleAction(addDirectPayment.fail, (state) =>
    produce(state, (draft) => {
      draft.addDirectPaymentReqStat = AsyncStatus.FAIL;
    }),
  ),
]);
