import Api from './api';
import {BankInfo, DirectPayment} from 'src/store/ezidebit/types';

export class Ezidebit {
  static getBankInfo() {
    return Api.get('business/bank');
  }

  static addBankInfo(data: BankInfo) {
    return Api.post('business/bank', data);
  }

  static addDirectPayment(data: DirectPayment) {
    return Api.post('business/direct-payment', data);
  }
}
