import Api from './api';

export class Illion {
  static getInstitutions() {
    return Api.get('external/illion/institutions');
  }

  static loginIllion(data: {slug: string; username: string; password: string}) {
    return Api.post('external/illion/login', data);
  }
}
