/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
// import {Theme} from 'src/theme';

interface FooterProps {
  page?: boolean
}

export const Footer = React.memo<FooterProps>(({page}) => {
  return (
    <FooterWraper page={page}>
    <Wrapper>
      <Block>
        <Text>Pay My Tax Copyright © 2022 All rights reserved</Text>
      </Block>
      <Block>
        <Link
          to="/terms-conditions"
          target="_blank"
          style={{
            fontSize: 17,
            paddingRight: 10,
            color: 'rgb(160, 153, 145)',
            textDecoration: 'underline',
          }}>
          Terms {'&'} Conditions
        </Link>
        <Link
          to="/privacy-policy"
          target="_blank"
          style={{
            fontSize: 17,
            paddingLeft: 10,
            color: 'rgb(160, 153, 145)',
            textDecoration: 'underline',
          }}>
          Privacy Policy
        </Link>
      </Block>
    </Wrapper>
    </FooterWraper>
  );
});

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #113958;
  padding: 0 50px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 454px) {
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 40px 20px 40px;
  };
  
`;

const Block = styled.div`
  width: 100p;
  height: 18px;
`;

const Text = styled.div`
  font-size: 17px;
  padding-top: 2px;
  height: 18px;
  font-weight: 600;
  color: white;
`;
const FooterWraper = styled.div<{page: any}>`
  position: absolute; 
  height: 19%;
  left: 0;
  width: 100%;
  color: white;
  text-align: center;
  `;
