import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import {ScheduleItem} from '../schedule-item';

interface ScheduleListProps {
  scheduler: ScheduleListItem[];
}

interface ScheduleListItem {
  date: string;
  amount: string;
}

export const ScheduleList = React.memo<ScheduleListProps>(({scheduler}) => {

  // const formatter = new Intl.NumberFormat('en-US', {
  //   style: 'currency',
  //   currency: 'USD',
  // });

  const scheduleList = scheduler.map((el, index) => (
    <ScheduleItem label={moment(el.date).format('DD/MM/YYYY')} value={el.amount} key={index} />
    
  ));

  return <List>{scheduleList}</List>;
});

const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;
