import React from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';

import {ArrowLeftSVG} from 'src/assets';

export const GoBack = () => {
  const history = useHistory();

  return (
    <IconWrapper onClick={() => history.goBack()}>
      <ArrowLeftSVG />
    </IconWrapper>
  );
};

const IconWrapper = styled.div`
  width: 18.4px;
  height: 17.4px;
  position: absolute;
  top: 50px;
  cursor: pointer;
`;
