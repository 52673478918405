import {createReducer} from 'deox';
import {produce} from 'immer';
import {AsyncStatus} from 'src/types';

import {login, sendCode, setDisplayHome} from './actions';
import {AuthProps} from './types';

const initialState: AuthProps = {
  phone: '',
  abn: '',
  token: '',
  refresh: '',
  loginReqStat: AsyncStatus.NONE,
  displayHome: true,
  sendCodeReqStat: AsyncStatus.NONE,
  payments: [],
};

export const authReducer = createReducer(initialState, (handleAction) => [
    //LOGIN
    handleAction(login.request, (state) =>
      produce(state, (draft) => {
        draft.loginReqStat = AsyncStatus.LOADING;
      }),
    ),
    handleAction(login.success, (state, {payload}) =>
      produce(state, (draft) => {
        draft.phone = payload.phone;
        draft.abn = payload.abn;
        draft.loginReqStat = AsyncStatus.SUCCESS;
      }),
    ),
    handleAction(login.fail, (state) =>
      produce(state, (draft) => {
        draft.loginReqStat = AsyncStatus.FAIL;
      }),
    ),
  
    handleAction(login.reset, (state) =>
      produce(state, (draft) => {
        draft.loginReqStat = AsyncStatus.NONE;
      }),
    ),

    handleAction(setDisplayHome, (state, {payload}) => 
      produce(state, (draft) => {
      draft.displayHome = payload;
      }),
    ),
  
    //SEND CODE
    handleAction(sendCode.request, (state) =>
      produce(state, (draft) => {
        draft.sendCodeReqStat = AsyncStatus.LOADING;
      }),
    ),
    handleAction(sendCode.success, (state, {payload}) =>
      produce(state, (draft) => {
        draft.token = payload.token;
        draft.refresh = payload.refresh;
        draft.sendCodeReqStat = AsyncStatus.SUCCESS;
        draft.payments = payload.payments;
      }),
    ),
    handleAction(sendCode.fail, (state) =>
      produce(state, (draft) => {
        draft.sendCodeReqStat = AsyncStatus.FAIL;
      }),
    ),
    handleAction(sendCode.reset, (state) =>
      produce(state, (draft) => {
        draft.sendCodeReqStat = AsyncStatus.NONE;
      }),
    ),
  ]);
  