import React from 'react';
import styled from 'styled-components';

import {Theme} from 'src/theme';

interface CheckboxProps {
  onClick(): void;
}

export const Checkbox = React.memo<CheckboxProps>(({onClick}) => {
  return (
    <Wrapper>
      <CheckboxInput type="checkbox" onClick={onClick} />
      <Label>
      I confirm that I have read, understand, accept and electronically sign (as though I’ve signed these documents by hand) the Loan Contract (including the Terms & Conditions), the Information Statement and the{' '}
        <Link
          href="https://www.splitpayments.com.au/ddr/"
          target="_blank">
          Split Payments Direct Debit Request
        </Link>
        {' '} and the {' '}
        <Link
          href="https://www.splitpayments.com.au/ddrsa/"
          target="_blank">
          Direct Debit Request Service Agreement
        </Link>;

      I confirm that this credit limit will still meet my requirements and objectives for the loan purpose I indicated in my application (notwithstanding that the credit limit may be less than I originally requested);

      I confirm that there are no foreseeable reasons that will affect my ability to meet my repayments on this credit facility; 

      I confirm that I am willing and able to reduce some of my expenses to afford my repayments on this credit facility.{' '}
      </Label>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%;
  margin: 10px 0;
  display: flex;
  align-items: center;
  margin-bottom: 100px;
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  color: ${Theme.colors.black3};
`;

const Link = styled.a`
  text-decoration: none;
  color: ${Theme.colors.purple};
`;

const CheckboxInput = styled.input`
  margin: 0;
  margin-right: 10px;
  margin-bottom: 81px;
`;
