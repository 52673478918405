import React, {useState, useEffect, Fragment} from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {
  PageWrapper,
  Button,
  Logo,
  GoBack,
  Input,
  ErrorMessage,
} from 'src/components';
import {ScheduleItem, ScheduleList, Checkbox} from './components';
import {Theme} from 'src/theme';
import {RootState} from 'src/store/types';
import {useAction} from 'src/utils/hooks';
import {addPaymentScheduler} from 'src/store/payments/actions';
import {getBankInfo, addBankInfo, addDirectPayment} from 'src/store/ezidebit/actions';
import {AsyncStatus} from 'src/types';

interface TextProps {
  margin?: string;
}

export const PaymentSchedule = React.memo(() => {
  const {abn} = useSelector((state: RootState) => state.auth);
  const getBankInfoAction = useAction(getBankInfo.request);
  const {paymentScheduler, payInfo, addPaymentSchedulerReqStat} = useSelector(
    (state: RootState) => state.payments,
    );
    const {activePayment} = useSelector((state: RootState) => state.payments);
    const {bankInfo, addbankInfoReqStat, error,addDirectPaymentReqStat} = useSelector(
      (state: RootState) => state.ezidebit,
      );

      useEffect(() => {
        getBankInfoAction()
          console.log(bankInfo, 'bankInfo')
          setBsbNumber(bankInfo.bsb);
          setAccountNumber(bankInfo.account_number);
          setAccoundHolderName(bankInfo.account_name);
          // eslint-disable-next-line
      }, [bankInfo.bsb])
   

  const [checkboxValue, setCheckboxValue] = useState<boolean>(false);
  const [bsbNumber, setBsbNumber] = useState<string>('');
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [accountHoldername, setAccoundHolderName] = useState<string>('');
  const [isShedulerReady, setIsschedulerReady] = useState<boolean>(false);
  const [localError, setLocalError] = useState<boolean>(false)

  const history = useHistory();

  const addPaymentSchedulerAction = useAction(addPaymentScheduler.request);
  const addBankInfoAction = useAction(addBankInfo.request);
   
  const addDirectpaymentAction = useAction(addDirectPayment.request);


  useEffect(() => {
    console.log(bsbNumber,'bsbNumber')
    console.log(accountNumber,'accountNumber')
    console.log(accountHoldername,'accountHoldername')
  },[accountHoldername,accountNumber,bsbNumber])




  useEffect(() => {
    if (addbankInfoReqStat === AsyncStatus.SUCCESS) {
      console.log('SUCCESS')
      addDirectpaymentAction({
        payment: activePayment.id,
        amount: String(payInfo.payNowAmount),
      });
    } else {
      console.log('FAILED')
    }
  },[
    addbankInfoReqStat,
    activePayment,
    addDirectpaymentAction,
    payInfo
  ])
    
  useEffect(() => {
    !bankInfo.bsb && getBankInfoAction();
    
    addbankInfoReqStat === AsyncStatus.SUCCESS &&
    addDirectPaymentReqStat === AsyncStatus.SUCCESS &&
      (Number(payInfo.payLaterAmount) === 0
        ? history.push('/new-payment/app-submitted')
        : window.location.href = `https://scv.bankstatements.com.au/QDWC-PMT${abn}` 
      );

    if (!isShedulerReady) {
      console.log("HEELLO")
      addPaymentSchedulerAction({amount: String(payInfo.payNowAmount)});
      setIsschedulerReady(true);
    }
  }, [
    addPaymentSchedulerAction,
    addDirectPaymentReqStat,
    addbankInfoReqStat,
    bankInfo.bsb,
    getBankInfoAction,
    history,
    isShedulerReady,
    payInfo.payLaterAmount,
    payInfo.payNowAmount,
    abn,
  ]);
  // const [disable, setDisable] = useState(false);
  const onButtonClick = () => {
  //  if(disable) {
  //    console.log('Disable');
  //  } else {
    addBankInfoAction({
      account_name: accountHoldername,
      bsb: bsbNumber,
      account_number: accountNumber,
    });
  };
    // setDisable(true);
  // };

  return (
    <Wrapper>
      <PageWrapper>
        <GoBack />
        <LogoWrapper>
          <Logo />
        </LogoWrapper>

        {addPaymentSchedulerReqStat === AsyncStatus.SUCCESS && (
          <Fragment>
            <HeaderText>Direct Debit Account Details</HeaderText>
            <Text>Funds will be debited from this account</Text>
            <Input
              onChange={(e: any) =>
                {(/^[0-9]*$/g.test(e.target.value) || e.target.value === '') &&
                e.target.value.length <= 6 &&
                setBsbNumber(e.target.value);
                setLocalError(true)}
              }
              type="text"
              labelText="BSB number"
              value={bsbNumber}
              mb0={addbankInfoReqStat === AsyncStatus.FAIL && !bsbNumber}
            />
            {addbankInfoReqStat === AsyncStatus.FAIL && !bsbNumber && (
              <ErrorMessage message="This field is required" />
            )}

            <Input
              onChange={(e: any) =>
                {(/^[0-9]*$/g.test(e.target.value) || e.target.value === '') &&
                e.target.value.length <= 9 &&
                setAccountNumber(e.target.value);
                setLocalError(true)
              }
              }
              type="text"
              labelText="Bank Account Number"
              value={accountNumber}
              mb0={addbankInfoReqStat === AsyncStatus.FAIL && !accountNumber}
            />
            {addbankInfoReqStat === AsyncStatus.FAIL && !accountNumber && (
              <ErrorMessage message="This field is required" />
            )}

            <Input
              onChange={(e: any) =>
                {e.target.value.length <= 32 &&
                setAccoundHolderName(e.target.value);
                // setLocalError(true)
              }
                
              }
              type="text"
              labelText="Account Holder Name"
              value={accountHoldername}
              mb0={
                addbankInfoReqStat === AsyncStatus.FAIL && !accountHoldername
              }
            />
            {addbankInfoReqStat === AsyncStatus.FAIL && !accountHoldername && (
              <ErrorMessage message="This field is required" />
            )}
            <ErrorMessage message="" /> 
            {bankInfo.account_name && (bankInfo.account_number !== accountNumber || bankInfo.bsb !== bsbNumber) && localError && <ErrorMessage message="All future payments will be deducted from this account" /> }

            <HeaderText>Direct Debit Schedule</HeaderText>
            <ScheduleList scheduler={paymentScheduler.scheduler} />
            <ScheduleItem
              label="TOTAL"
              value={paymentScheduler.total}
              isTotal
            />

            <MicroText margin="10px 0">*All prices are in AUD</MicroText>
            <MicroText>
              You request and authorise Split Payments Pty Ltd (User ID #454146, 492448, 500298, 507533, 518403, 518404, 531942, 543948, 543950, 543949, 543954, 543947, 543955, 543956, 543957, 
              543958, 543962, 600993, 600994, 600995, 600996, 600997, 600998, 600999, 601001, 601002, 601003, 601004, 601005, 601006, 601007, 601008, 612056, 612057, 613600, 613601, 613602, 
              613603, 613604, 613605, 613606, 613607, 613608, 613609) on behalf of the Payment Initiator with whom you have a direct debit agreement, to arrange through its own financial 
              institution, to debit from your nominated account any amount the Payment Initiator has deemed payable by you.
              This debit or charge will be made through the Bulk Electronic Clearing System (BECS) from your account held at the financial institution you have nominated below and will be 
              subject to the terms and conditions of the Direct Debit Request Service Agreement.
              Split Payments does not accept any liability for the provision, merchantable quality or fitness for purpose of the underlying goods or services provided to the User by 
              the Payment Initiator and/or merchant and therefore the User holds Split Payments harmless for any claim that may arise from the non-provision of services by 
              the Payment Initiator and/or merchant or any other claim that may be made against the Payment Initiator and/or merchant under Consumer Law.
            </MicroText>
            <Checkbox onClick={() => setCheckboxValue(!checkboxValue)} />
          </Fragment>
        )}
      </PageWrapper>
      <ButtonWrapper>
        {!!error.length && <ErrorMessage message={error} />}

        <Button
          onClick={checkboxValue ? onButtonClick : () => null}
          buttonStyles={{
            backgroundColor: checkboxValue
              ? Theme.colors.purple
              : Theme.colors.gray,
          }}
          text="NEXT"
          loading={addbankInfoReqStat === AsyncStatus.LOADING || addDirectPaymentReqStat === AsyncStatus.LOADING || addPaymentSchedulerReqStat === AsyncStatus.LOADING}
        />
      </ButtonWrapper>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  max-width: 1020px;
  width: 100%;
  margin: auto;
  position: relative;
  min-height: 100vh;
`;

const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const HeaderText = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  color: ${Theme.colors.black};
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 10px;
`;

const MicroText = styled.p`
  font-size: 9px;
  color: ${Theme.colors.black3};
  margin: ${(props: TextProps) => props.margin};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  position: absolute;
  bottom: 37px;
  box-sizing: border-box;
  max-width: 1020px;
`;
