import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {Illion} from 'src/components';
import {addDirectPayment} from 'src/store/ezidebit/actions';
import {useAction} from 'src/utils/hooks';
import {RootState} from 'src/store/types';
import {AsyncStatus} from 'src/types';

export const IllionIframe = React.memo(() => {
  const {payInfo, activePayment} = useSelector((state: RootState) => state.payments);
  const {addDirectPaymentReqStat} = useSelector(
    (state: RootState) => state.ezidebit,
  );

  const addDirectpaymentAction = useAction(addDirectPayment.request);

  useEffect(() => {
    addDirectPaymentReqStat === AsyncStatus.NONE &&
      payInfo.payNowAmount !== 0 &&
      addDirectpaymentAction({
        payment: activePayment.id,
        amount: String(payInfo.payNowAmount),
      });
  }, [
    addDirectPaymentReqStat,
    addDirectpaymentAction,
    payInfo.payNowAmount,
    activePayment.id,
  ]);

  return <Illion />;
});
