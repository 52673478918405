import React from 'react';
import styled from 'styled-components';

import {LogoSVG} from 'src/assets';

export const Logo = () => {
  return (
    <LogoWrapper>
      <LogoSVG />
    </LogoWrapper>
  );
};

const LogoWrapper = styled.div`
  margin: 47px 0;
  display: flex;
  justify-content: center;
  height: 50px;
  width: 50px;
`;
