import React from 'react';
import styled from 'styled-components';
import {RootState} from 'src/store/types';
import {useSelector} from 'react-redux';

// Change TEST iframe url to real§
export const Illion = React.memo(() => {
  const {abn} = useSelector((state: RootState) => state.auth);
  
  return (
    <Container>
      <div id="bankstatements" data-code="MBHL"></div>
      <script
        src="https://www.bankstatements.com.au/js/loader.js"
        async></script>
      {/* <noscript> */}
      <iframe
        id="illion"
        title="illion"
        frameBorder="none"
        src={`https://scv-test.bankstatements.com.au/PMTX${abn}`}
        // src={`https://scv.bankstatements.com.au/QDWC-PMT${abn}`}
        style={{width: '100%', height: '100vh'}}></iframe>
      {/* </noscript> */}
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
`;
