import React, {Fragment, useState} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';

import {useSelector} from 'react-redux';
import {RootState} from 'src/store/types';

import {Theme} from 'src/theme';
import {PageWrapper, GoBack, Input, Button, DatePicker} from 'src/components';

export const DirectPayment = React.memo(() => {
  const [dateValue, setDateValue] = useState<Date>(new Date());
  const [amountValue, setAmountValue] = useState<string>('');
  const [referenceNumber, setReferenceNumber] = useState<string>('');

  const history = useHistory();

  const {payment} = useSelector((state: RootState) => state.payments);
  const {pay_now_amount} = useSelector((state: RootState) => state.payments);

  const onDateChange = (value: Date) => {
    new Date(value).getTime() > dateValue.getTime() &&
      setDateValue(new Date(value));
  };

  const goToNextStep = () => {
    if (Number(payment.total_amount) === pay_now_amount) {
      history.push('/new-payment/app-submitted');
    } else {
      history.push('/new-payment/illion');
    }
  };

  return (
    <Wrapper>
      <Fragment>
        <PageWrapper>
          <GoBack />
          <Header>Direct payment</Header>
          <DatePicker
            value={dateValue}
            onChange={onDateChange}
            label="Transaction date"
          />
          <Input
            onChange={(e: any) =>
              (Number(e.target.value) || e.target.value === '') &&
              setReferenceNumber(e.target.value)
            }
            type="text"
            labelText="Transaction reference number"
            value={referenceNumber}
          />
          <Input
            onChange={(e: any) =>
              (Number(e.target.value) || e.target.value === '') &&
              setAmountValue(e.target.value)
            }
            type="text"
            labelText="Amount"
            value={amountValue}
            leftIcon="$"
          />
        </PageWrapper>

        <ButtonWrapper>
          <Button onClick={goToNextStep} text="Next step" />
        </ButtonWrapper>
      </Fragment>
    </Wrapper>
  );
});

const Header = styled.h2`
  fonr-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: ${Theme.colors.black};
  margin-top: 80px;
  margin-bottom: 17px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 37px;
  padding: 0 20px;
  box-sizing: border-box;
  max-width: 1020px;
`;

const Wrapper = styled.div`
  max-width: 1020px;
  width: 100%;
  margin: auto;
`;
