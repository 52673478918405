import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
// import {useHistory} from 'react-router-dom';

// import {PageWrapper, GoBack, Button} from 'src/components';
// import {Theme} from 'src/theme';
// import {InfoItem} from './components';
import {RootState} from 'src/store/types';
import {useAction} from 'src/utils/hooks';
import {
  getBankInfo,
  // addBankInfo,
  // addDirectPayment,
} from 'src/store/ezidebit/actions';
import {AsyncStatus} from 'src/types';

export const Ezidebit = React.memo(() => {
  const {payInfo} = useSelector((state: RootState) => state.payments);
  const {
    bankInfo,
    getBankInfoReqStat,
    // addbankInfoReqStat,
    // addDirectPaymentReqStat,
  } = useSelector((state: RootState) => state.ezidebit);
  // const history = useHistory();

  const redirectUrl =
    payInfo.payLaterAmount === 0
      ? 'http://localhost:3000/new-payment/app-submitted'
      : 'http://localhost:3000//new-payment/illion';

  const getBankInfoAction = useAction(getBankInfo.request);
  // const addbankInfoAction = useAction(addBankInfo.request);
  // const addDirectpaymentAction = useAction(addDirectPayment.request);

  // const onButtonClick = () => {
  //   if (getBankInfoReqStat === AsyncStatus.SUCCESS) {
  //     addbankInfoAction(bankInfo);
  //   }
  // };

  useEffect(() => {
    if (getBankInfoReqStat === AsyncStatus.NONE) {
      getBankInfoAction();
    }

    // if (
    //   addbankInfoReqStat === AsyncStatus.SUCCESS &&
    //   addDirectPaymentReqStat === AsyncStatus.NONE
    // ) {
    //   addDirectpaymentAction({
    //     payment: payment.id,
    //     amount: String(payInfo.payNowAmount),
    //   });
    // }

    // if (
    //   addbankInfoReqStat === AsyncStatus.SUCCESS &&
    //   addDirectPaymentReqStat === AsyncStatus.SUCCESS
    // ) {
    //   payInfo.payNowAmount !== payInfo.totalAmount
    //     ? history.push('/new-payment/illion')
    //     : history.push('/new-payment/app-submitted');
    // }
  }, [getBankInfoAction, getBankInfoReqStat]);

  return (
    <IframeContainer>
      {getBankInfoReqStat === AsyncStatus.SUCCESS && (
        <iframe
          id="ezidebit"
          title="ezidebit"
          frameBorder="none"
          src={`https://widget.demo.ezidebit.com.au/account/edit?dk=DF190426-67CE-4CCE-66EA-E91D676CA63E&cr=pmt-${bankInfo.customer_id}&callback=${redirectUrl}`}
          style={{width: '100%', height: '100vh'}}></iframe>
      )}
    </IframeContainer>
    // <Wrapper>
    //   <Fragment>
    //     <PageWrapper>
    //       <GoBack />
    //       <Header>Bank account details</Header>
    //       {payInfo && payInfo.totalAmount && (
    //         <Fragment>
    //           <InfoItem label="BSB" value={bankInfo.bsb} />
    //           <InfoItem
    //             label="Account Number"
    //             value={bankInfo.bank_number}
    //           />
    //           <InfoItem
    //             label="Account Holder Name"
    //             value={bankInfo.holder_name}
    //           />
    //         </Fragment>
    //       )}
    //       {(addbankInfoReqStat === AsyncStatus.FAIL ||
    //         addDirectPaymentReqStat === AsyncStatus.FAIL) && (
    //         <ErrorMessage>
    //           Something went wrong. Try again later
    //         </ErrorMessage>
    //       )}
    //     </PageWrapper>
    //     <ButtonWrapper>
    //       <Button
    //         onClick={onButtonClick}
    //         text="Next step"
    //         loading={
    //           addbankInfoReqStat === AsyncStatus.LOADING ||
    //           addDirectPaymentReqStat === AsyncStatus.LOADING
    //         }
    //       />
    //     </ButtonWrapper>
    //   </Fragment>
    // </Wrapper>
  );
});

const IframeContainer = styled.div`
  width: 100%;
`;

// const Header = styled.h2`
//   fonr-size: 24px;
//   font-weight: 600;
//   line-height: 36px;
//   color: ${Theme.colors.black};
//   margin-top: 80px;
//   margin-bottom: 17px;
// `;

// const ButtonWrapper = styled.div`
//   width: 100%;
//   position: absolute;
//   bottom: 37px;
//   padding: 0 20px;
//   box-sizing: border-box;
//   max-width: 1020px;
// `;

// const Wrapper = styled.div`
//   max-width: 1020px;
//   width: 100%;
//   margin: auto;
// `;

// const ErrorMessage = styled.p`
//   font-size: 13px;
//   line-height: 13px;
//   font-weight: 400;
//   color: ${Theme.colors.red};
// `;
