/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import styled from 'styled-components';

interface PrivacyProps {}

export const Privacy = React.memo<PrivacyProps>(() => {
  return (
    <Wrapper>
      <Header>
        <Number>1.</Number>Introduction
      </Header>
      <Text>
        This Privacy Policy explains how and why Tax Hitech Pty Ltd ACN 642 032
        666 (<WhiteHeader>Tax Hitech, we</WhiteHeader> or{' '}
        <WhiteHeader>us</WhiteHeader>) collects your personal information, how
        we use it and what controls you have over it. Tax Hitech is committed to
        complying with its obligations under the Privacy Act 1988 (Cth),{' '}
        <i>Privacy Amendment (Enhancing Privacy Protection) Act 2012 (Cth) </i>
        (collectively,<WhiteHeader>Privacy Act</WhiteHeader>) and the Australian
        Privacy Principles (<WhiteHeader>APP</WhiteHeader>) in respect of
        personal information.
      </Text>
      <Text>
        For the purposes of this Privacy Policy, “personal information” is any
        information that can be used to identify you, and may include (but is
        not limited to) information such as your name, address, email address,
        phone number or credit card details (collectively,{' '}
        <WhiteHeader>Personal Information</WhiteHeader>).
      </Text>
      <Text>
        Please read this Privacy Policy carefully. Your use of our website,
        PayMyTax, and any of our associated websites, applications, platforms,
        software, products or services (<WhiteHeader>Solutions</WhiteHeader>),
        will constitute your consent to the terms of this Privacy Policy and our
        collection, transfer, processing, storage and disclosure of your
        Personal Information in accordance with this Privacy Policy. If you do
        not agree with this Privacy Policy, please do not use our Solutions .
      </Text>

      <Header>
        <Number>2.</Number>
        Collection of Personal Information
      </Header>
      <Text>
        Tax Hitech may collect various forms of Personal Information directly
        from you (via information you provide to us or information collected
        from your use of our Solutions) or from third parties in a lawful and
        fair way. Information collected will be reasonably necessary for the
        provision of the Solutions, including:
      </Text>
      <Text>
        <Letter>(a)</Letter>
        your name, date of birth and contact details, including your email
        address, telephone number and home, postal and business addresses;
      </Text>
      <Text>
        <Letter>(b)</Letter>
        identity data including your company and business name and number;
      </Text>
      <Text>
        <Letter>(c)</Letter>
        financial data, including your tax file number and banking, billing or
        other payment information;
      </Text>
      <Text>
        <Letter>(d)</Letter>
        transaction data, including details about payments to you from third
        parties, and payments from you to third parties, facilitated by our
        Solutions;
      </Text>
      <Text>
        <Letter>(e)</Letter>
        usage data, including information on your use our Solutions;
      </Text>
      <Text>
        <Letter>(f)</Letter>
        profile data, including your username and password; and
      </Text>
      <Text>
        <Letter>(g)</Letter>
        any other information relevant or incidental to our provision of the
        Solutions.
      </Text>
      <Text>
        We may collect information which comes within the definition of
        “Sensitive Information” for the purposes of the Privacy Act (Sensitive
        Information); however, this will only ever be where it is reasonably
        necessary for or directly related to the Solutions we provide and where
        you have consented to its collection (including where you submit this
        information to us), or its collection is permitted or authorised by law.
      </Text>

      <Header>
        <Number>3.</Number>
        Use of Personal Information
      </Header>
      <Text>
        We collect, hold, use and disclose your Personal Information for the
        following purposes:
      </Text>
      <Text>
        <Letter>(a)</Letter>
        for the purpose(s) for which it was disclosed to or collected by us;
      </Text>
      <Text>
        <Letter>(b)</Letter>
        facilitating interactions with you in the course of operating our
        business and providing the Solutions;
      </Text>
      <Text>
        <Letter>(c)</Letter>
        responding to your enquiries and information requests;
      </Text>
      <Text>
        <Letter>(d)</Letter>
        storing information at third-party centres;
      </Text>
      <Text>
        <Letter>(e)</Letter>
        complying with our legal or regulatory obligations, including to
        authorised regulatory bodies;
      </Text>
      <Text>
        <Letter>(f)</Letter>
        to prevent fraud and other criminal activities;
      </Text>
      <Text>
        <Letter>(g)</Letter>
        to include in a database compiled by us for use in direct marketing of
        promotions, Solutions and other services;
      </Text>
      <Text>
        <Letter>(h)</Letter>
        to seek your feedback in relation to particular Solutions, service
        satisfaction and our relationship with you and to manage any complaints
        or concerns you may raise against us;
      </Text>
      <Text>
        <Letter>(i)</Letter>
        to assist us in running our business and improving the quality of the
        Solutions, including staff training, accounting, risk management, record
        keeping, archiving, systems development, developing new Solutions and
        services and undertaking planning, research and development;
      </Text>
      <Text>
        <Letter>(j)</Letter>
        for secondary purposes where it would be reasonable to expect us to do
        so, and that secondary purpose is related (or directly related in the
        case of Sensitive Information) to the primary purpose for which it was
        collected; and
      </Text>
      <Text>
        <Letter>(k)</Letter>
        for any other purposes for which you have consented to from time to
        time.
      </Text>
      <Text>
        You expressly consent and authorise us to collect, use and disclose your
        Personal Information for the above purposes. You may at any time refuse
        to provide us with your Personal Information or withdraw your consent
        for us to collect, use and disclose your Personal information for the
        above purposes; however, this will result in us being unable to provide
        the Solutions to you.
      </Text>
      <Text>
        If you choose to provide Personal Information belonging to a third
        party, we will assume that you have the third party’s fully informed
        consent to do so in compliance with any Privacy Act or APP obligations
        imposed on you. An example being where you use our Solutions on behalf
        of a business, and in doing so, provide us Personal Information of a
        business partner, employee/employer, principal/agent, etc. (being a
        non-exhaustive, illustrative list only).
      </Text>
      <Text>
        In some instances, Tax Hitech may collect non-personal (aggregate or
        demographic) data through cookies, web and system logs, web beacons and
        other similar applications. This information is used to improve the
        usability, performance, and effectiveness of our Solutions and to
        troubleshoot problems. By your use of our Solutions, we may receive
        anonymous network information relating to, amongst other things, the
        performance of the Solution and the types of devices attached to the
        network. This information assists in our improvement of the Solutions
        and your network, as well as in allowing us to provide alerts via the
        Solution about available software updates and upgrades.
      </Text>

      <Header>
        <Number>4.</Number>
        Sharing of Personal Information
      </Header>
      <Text>
        We will ask for your consent before we use or share your Personal
        Information for any purpose other than for the reason you provided it or
        as otherwise provided by this Privacy Policy. We may share your Personal
        Information in the following ways (being a non-exhaustive, illustrative
        list only):
      </Text>
      <Text>
        <Letter>(a)</Letter>
        within Tax Hitech for the purposes of data processing, storage, backup
        and to provide you with the Solutions;
      </Text>
      <Text>
        <Letter>(b)</Letter>
        with our business partners, service providers, authorised third-party
        agents or contractors and government agencies (including the Australian
        Taxation Office). We provide these third parties with the Personal
        Information reasonably necessary to provide our Solutions, or any
        service or transaction reasonably incidental to that;
      </Text>
      <Text>
        <Letter>(c)</Letter>
        to comply with the law or legal process (such as responding to subpoenas
        or court orders) and to exercise our legal rights or defend against
        legal claims; and
      </Text>
      <Text>
        <Letter>(d)</Letter>
        to investigate, prevent, or act regarding illegal activities, suspected
        or potential fraud, brand protection matters, situations involving
        potential threats to the physical safety of any person, violations of
        Tax Hitech’s terms of use, or as otherwise required by law.
      </Text>

      <Header>
        <Number>5.</Number>
        Storage and protection of Personal Information
      </Header>
      <Text>
        Your Personal Information will be stored in computer systems and
        databases operated either by us or an external service provider. We will
        utilise a combination of industry-standard security technologies,
        procedures, and organisational measures to protect it from unauthorised
        access, use or disclosure. However, we are not responsible, and cannot
        guarantee, the security of Personal Information transmitted via the
        internet, which you transmit at your own risk. We recommend that you
        take every precaution in protecting your Personal Information when you
        are on the internet, including by using a secure browser and creating
        complex passwords (featuring a combination of letters and numbers) which
        you change often.
      </Text>

      <Header>
        <Number>6.</Number>
        Direct marketing
      </Header>
      <Text>
        We will only send you direct marketing communications and information on
        our Solutions, including to inform you of other Solutions available from
        Tax Hitech and its affiliates, via mail, email or social media with your
        consent. You may opt-out of receiving marketing communications from us
        by contacting us at the details below or by using opt-out facilities
        provided in our communications. We do not provide your Personal
        Information to other organisations for the purposes of their direct
        marketing and will not sell, rent or lease our customer lists to third
        parties.
      </Text>

      <Header>
        <Number>7.</Number>
        Retention, removal and reinstatement of Personal Information
      </Header>
      <Text>
        We will only retain your Personal Information for as long as reasonably
        necessary to fulfil the purposes for which we collected it, including
        satisfying any legal, regulatory, tax, accounting or reporting
        requirements. We may retain your Personal Information for a longer
        period in the event of a complaint or if we reasonably believe there is
        a prospect of litigation, regulatory action or investigation in respect
        of our relationship with you.
      </Text>
      <Text>
        To determine the appropriate retention period for your Personal
        Information, we consider the amount, nature and sensitivity of the
        information, the potential risk of harm from its unauthorised use or
        disclosure, the reason for its collection and whether we can achieve
        those purposes through other means, and applicable legal, regulatory,
        tax, accounting or other requirements.
      </Text>
      <Text>
        Where we anonymise your personal information (so that it can no longer
        be associated with you) for research or statistical purposes, we may use
        this information indefinitely without further notice to you.
      </Text>

      <Header>
        <Number>8.</Number>
        Use of cookies and other web technologies
      </Header>
      <Text>
        To improve your experience with our Solutions, we may use automatic data
        collection tools, such as cookies (both persistent and session),
        embedded web links and web beacons. These tools may be used for
        recording preferences, conducting internal analytics and research to
        improve our Solutions, assisting with marketing and delivering
        functionality.
      </Text>
      <Text>
        You may refuse to accept cookies by selecting the appropriate setting on
        your internet browser. However, please note that if you do this, you may
        not be able to use the full functionality of our Solutions.
      </Text>

      <Header>
        <Number>9.</Number>
        Third-party sites and linked websites
      </Header>
      <Text>
        For your convenience and to improve the usage of our Solutions, we may
        insert links to third-party websites, applications or resources, to
        which this Privacy Policy does not apply.
      </Text>
      <Text>
        Tax Hitech is not responsible for those third-party websites,
        applications or resources. If you access such websites, applications or
        resources, you do so at your own risk and we give no representation or
        warranty regarding a third party’s privacy practices. We encourage you
        to read the privacy statements and policies of every website,
        application or resource you use.
      </Text>
      <Text>
        When we do link to a third-party website, application or resource, Tax
        Hitech does not in any way endorse that website, application, resource
        or its contents. Our Privacy Policy does not cover the use of cookies by
        any third party.
      </Text>

      <Header>
        <Number>10.</Number>
        Cross-border data flows
      </Header>
      <Text>
        In providing the Solutions to you, we may share your Personal
        Information with third parties based overseas. Our Privacy Policy and
        practices are designed to provide a globally consistent level of
        protection for your Personal Information. Even in countries whose laws
        provide less protection for your information, Tax Hitech will handle
        your information in the manner described here, in accordance with the
        APPs. However, by providing any information to us, you fully understand:
      </Text>
      <Text>
        <Letter>(a)</Letter>
        and unambiguously consent to the transfer, processing and storage of
        such information outside of Australia, to locations where data
        protection standards may be different. In providing this consent, you
        expressly acknowledge that we are not required to comply with the
        obligations imposed on us by APP 8.1;
      </Text>
      <Text>
        <Letter>(b)</Letter>
        that the overseas third party may not be subject to privacy obligations
        consistent with the Privacy Act or APPs;
      </Text>
      <Text>
        <Letter>(c)</Letter>
        that you will not be able to seek redress under the Privacy Act; and
      </Text>
      <Text>
        <Letter>(d)</Letter>
        that we will not be accountable for any acts or omissions of the
        overseas third party in relation to the handling of your Personal
        Information.
      </Text>

      <Header>
        <Number>11.</Number>
        Accessing and updating Personal Information
      </Header>
      <Text>
        We will take reasonable steps to collect and store Personal Information
        that is accurate, complete and relevant, having regard to the purposes
        for which the Personal Information is used or disclosed.
      </Text>
      <Text>
        Where requested, we will provide you with a copy of your Personal
        Information, provided that the request is made in accordance with the
        APPs. We will also update your Personal Information if you inform us
        that it is inaccurate, out-of-date, incomplete, irrelevant or
        misleading. It is your obligation to inform us as and when this is the
        case.
      </Text>
      <Text>
        There are no charges for requesting access to or the correction of your
        Personal Information; however, if the volume of information we hold is
        excessively large, we reserve our rights to charge you a reasonable
        administration fee (including for photocopying).
      </Text>
      <Text>
        You can contact our privacy officer regarding access to or correction of
        your information by any of the following methods:
      </Text>
      <Text>
        <WhiteHeader>Email: </WhiteHeader>
        {'  '}support@paymytax.com.au
      </Text>
      <Text>
        We will respond to your access or correction request within a reasonable
        time in accordance with our obligations under the Privacy Act. If we
        refuse your request, where reasonable, we will provide you with our
        reasons and information on your possible next steps.
      </Text>
      <Text>
        In order to protect the confidentiality of your Personal Information,
        you will only ever receive details of the information when we are
        satisfied that the information relates to you. Accordingly, we may
        request documentation from you which confirms your identity before
        passing on your Personal Information.
      </Text>

      <Header>
        <Number>12.</Number>
        Complaints, breaches and contact
      </Header>
      <Text>
        If you have a complaint about our Privacy Policy or the collection, use,
        disposal or destruction of your Personal Information, your complaint
        should be directed in the first instance to our privacy officer at the
        details set out <WhiteHeader>above</WhiteHeader>.
      </Text>
      <Text>
        We will investigate and attempt to resolve the issue in accordance with
        the Privacy Act. We will notify you of the outcome of this investigation
        and any subsequent investigations. If you are not satisfied with the
        outcome of this process, you may contact the Office of the Australian
        Information Commissioner <WhiteHeader>(OAIC)</WhiteHeader>.
      </Text>

      <Header>
        <Number>13.</Number>
        Updates to the Privacy Policy
      </Header>
      <Text>
        We may change this Privacy Policy at our discretion. If we do so, the
        latest version of privacy policy will be available to you via a link on
        our Solutions and it will apply to all of your Personal Information held
        by us at that time.
      </Text>

      <Header>
        <Number>14.</Number>
        Further information
      </Header>
      <Text>
        Should you wish to read more information on the Privacy Act, we
        recommend that you visit the website of the OAIC at{' '}
        <LinkWrapper href="www.oaic.gov.au" target="_blank">
          www.oaic.gov.au
        </LinkWrapper>
      </Text>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%;
  font-family: adonis-web;
  height: 100%;
  background-color: #113958;
  padding: 50px 270px;
  overflow: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    background: transparent;
  }
  @media (max-width: 1470px) {
    padding: 50px 100px;
  }
  @media (max-width: 900px) {
    padding: 50px 40px;
  }
  @media (max-width: 450px) {
    padding: 30px 10px;
  }
`;

const Text = styled.p`
  font-size: calc((1 - 1) * 1.2vw + 1rem);
  color: white; 
  font-weight: 400;
  color: #d3d3d3;
  padding: 9px 0;
  line-height: 28px;
`;

const Header = styled.h3`
  color: white;
  font-weight: 400;
  font-size: calc((1.6 - 1) * 1.2vw + 1rem);
  padding: 30px 2px;
`;

const Number = styled.div`
  color: white;
  display: inline-block;
  font-weight: 400;
  padding-right: 15px;
`;

const WhiteHeader = styled.span`
  font-weight: bold;
  color: white;
`;

const Letter = styled.div`
  color: white;
  display: inline-block;
  padding-right: 10px;
  padding-left: 15px;
`;

const LinkWrapper = styled.a`
  color: rgb(225, 204, 190);
`;
