import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {Theme} from 'src/theme';
import {PageWrapper, Button, ErrorMessage} from 'src/components';
import {ProofItem} from './components';
import {RootState} from 'src/store/types';
import {useAction} from 'src/utils/hooks';
import {addLoanPayment} from 'src/store/payments/actions';
import {AsyncStatus} from 'src/types';
import {addProofFile, deleteProofFile} from 'src/store/payments/actions';


export const IdentityProof = React.memo(() => {
  const {
    proofImages = [],
    proofImagesPoints,
    addProofFileReqStat,
    addLoanPaymentReqStat,
    // xeroUrl,
    payInfo,
    activePayment,
  } = useSelector((state: RootState) => state.payments);

  const [earnedPoints, setEarnedPoints] = useState<number>(proofImagesPoints);
  const [isContinueButtonClicked, setIsContinueButtonClicked] = useState<
    boolean
  >(false);
  const deleteProofFileAction = useAction(deleteProofFile.request)
  const addLoanPaymentAction = useAction(addLoanPayment.request);
  const history = useHistory();
  // const [currentPathname, setCurrentPathname] = useState('')
  // const [currentSearch, setCurrentSearch] = useState('')

  // useEffect(() => {
     
  //   history.listen((newLocation, action) => {
  //     if (action === "PUSH") {
  //       if (
  //         newLocation.pathname !== currentPathname ||
  //         newLocation.search !== currentSearch
  //       ) {
  //         // Save new location
  //         setCurrentPathname(newLocation.pathname)
  //         setCurrentSearch(newLocation.search);

  //         // Clone location object and push it to history
  //         history.push({
  //           pathname: newLocation.pathname,
  //           search: newLocation.search
  //         });
  //       }
  //     } else {
  //       // Send user back if they try to navigate back
  //       history.go(1);
  //     }
  //   });
  //   // eslint-disable-next-line
  // } ,[currentPathname])


  useEffect(() => {
    console.log('USEEFFECT')
    if (
      addLoanPaymentReqStat === AsyncStatus.SUCCESS &&
      isContinueButtonClicked
    ) {
      console.log('GOODRESULT')
      history.push('/new-payment/app-submitted');
    }
  }, [addLoanPaymentReqStat, history, isContinueButtonClicked]);

  const handleAddPoints = (value: number) => {
    setEarnedPoints((state) => state + value);
  };

  const onContinueButtonClick = () => {
    setEarnedPoints(() => 0);
    if (addProofFileReqStat !== 2 && (!earnedPoints || earnedPoints) < 100) {
      return;
    }

    addLoanPaymentAction({
      loan_amount: +payInfo.payLaterAmount.toFixed(2),
      payment: activePayment.id,
    });
    setIsContinueButtonClicked(true);
    // if (!!xeroUrl) {
    //   history.push(xeroUrl);
    // } else {
    //   history.push('/new-payment/app-submitted');
    // }
    // if(proofImages.length) {
    //   if (proofImages.length === 3) {
    //     setTimeout(() => {
    //       deleteProofFileAction(proofImages[0].id)
    //       deleteProofFileAction(proofImages[1].id)
    //       deleteProofFileAction(proofImages[2].id)
    //     },2000)
    //   } else if(proofImages.length === 2) {
    //     setTimeout(() => {
    //       deleteProofFileAction(proofImages[0].id)
    //       deleteProofFileAction(proofImages[1].id)
    //     },2000)
    //   } 
    // }
    setTimeout(() => {
            deleteProofFileAction(2)
          },2000)
    // deleteProofFileAction(2)
  };
  const proofFileAction = useAction(addProofFile.request);


  const onSelectImage = (files: File[], image: string[], points: string, id: number ) => {
    const [img] = image;
    const [file] = files;
     

    if (img) {
      handleAddPoints(Number(points));

      let formData = new FormData();
      formData.append('id', `${id}`);
      formData.append('points', points);
      formData.append('type', `${id}`);
      formData.append('file', file);
      formData.append('customer', `3324`);

      proofFileAction(formData);
    }
  };


  return (
    <PageWrapper>
      {/* <GoBack /> */}
      <Header>Proof of identity</Header>
      <Text>
        Please upload documentation from the list below which is required to
        meet the 100 point proof of identity (ID)
      </Text>
      <ProofItem
        isAvailable={!proofImages.some((el) => el.type === 1)}
        points="70"
        id={1}
        type={1}
        addPoints={handleAddPoints}
        options={[
          '- Passport current or expired within the last two years, not cancelled',
          '- Birth certificate: not an extract',
          '- Australian citizenship certificate.',
        ]}
        onSelectImage={onSelectImage}
      />
      <ProofItem
        isAvailable={!proofImages.some((el) => el.type === 2)}
        points="40"
        id={2}
        type={2}
        addPoints={handleAddPoints}
        options={["- Driver's licence including foreign licences."]}
        onSelectImage={onSelectImage}

      />
      <ProofItem
        isAvailable={!proofImages.some((el) => el.type === 3)}
        points="40"
        id={3}
        type={3}
        addPoints={handleAddPoints}
        options={[
          '- Centrelink card',
          '- Public service employee ID card',
          '- Security licence (OCBA)',
          '- Tertiary education ID card',
          '- Veteran Affairs gold card.',
        ]}
        onSelectImage={onSelectImage}

      />
      {addLoanPaymentReqStat === AsyncStatus.FAIL &&
        isContinueButtonClicked && (
          <ErrorMessage message="Application failed. Please try again." />
        )}

      <Button
        text="Submit application"
        onClick={() => { proofFileAction(new FormData()); onContinueButtonClick()}}
        buttonStyles={{
          backgroundColor:
            earnedPoints && earnedPoints >= 100 && addProofFileReqStat === 2
              ? Theme.colors.purple
              : Theme.colors.gray,
        }}
        loading={addLoanPaymentReqStat === AsyncStatus.LOADING}
      />
    </PageWrapper>
  );
});

const Header = styled.h2`
  fonr-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: ${Theme.colors.black};
  margin-top: 80px;
  margin-bottom: 11px;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 19.6px;
  color: ${Theme.colors.black3};
  font-weight: 400;
  margin-bottom: 19px;
`;
