/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';

import {
  Logo,
  Button,
  PageWrapper,
  GoBack,
  CodeInput,
  ErrorMessage,
} from 'src/components';
import {Theme} from 'src/theme';
import {RootState} from 'src/store/types';
import {Payment} from '../../store/payments/types';
import {sendCode, login, setDisplayHome, logOut} from 'src/store/auth/actions';
import {useAction} from 'src/utils/hooks';
import {HeaderMain} from 'src/components';
import {AsyncStatus} from 'src/types';
import {getPaymentById, setActivePayment, setPaymentId} from 'src/store/payments/actions';
//import { PaymentHistory } from '../payment-history';
// import { PaymentHistory } from '../payment-history';
//import { setActivePayment } from 'src/store/payments/actions';

export const LoginVerify = React.memo(() => {
  const [code, setCode] = useState<string>('');
  //const {payment} = useSelector((state: RootState) => state.payments);
  //const {paymentHistory} = useSelector((state: RootState) => state.payments);
  const {payments} = useSelector((state: RootState) => state.auth);
  //const [fromLink, setFromLink] = useState();
  const [isLOcationPhoneSaved, setIsLocationPhoneSaved] = useState<boolean>(
    false,
  );

  const history = useHistory();
  const location = useLocation();

  console.log(location.search);

  const {phone, abn, sendCodeReqStat} = useSelector(
    (state: RootState) => state.auth,
  );
  const {sendCodeErrorMessages} = useSelector(
    (state: RootState) => state.errors,
  );
  const {token} = useSelector(
    (state: RootState) => state.auth,
  );
  const sendCodeAction = useAction(sendCode.request);
  //const resetLoginAsyncStatus = useAction(login.reset);
  const resetSendCodeAsyncStatus = useAction(sendCode.reset);
  const dipslayHomeStatus = useAction(setDisplayHome);
  const resetStore = useAction(logOut);
  const getPaymentByIdStatus = useAction(getPaymentById.request);
  const resetPayment = useAction(getPaymentById.reset);
  const resetLoginAsyncStatus = useAction(login.reset);
  const activePaymentAction = useAction(setActivePayment);
  const activePaymentIdAction = useAction(setPaymentId);
  const loginAction = useAction(login.request);
  const lenght = useRef(0);
  console.log(
    location.search.slice(
      location.search.indexOf('=') + 1,
      location.search.indexOf('&'),
    ),
  );
  const locationPhone = location.search
    ? location.search.slice(
        location.search.indexOf('=') + 1,
        location.search.indexOf('&'),
      )
    : '';
    const paymentId = location.search.split('=')[2]

  useEffect(() => {
    if(token && location.search){
      resetStore();
    }
    console.log(token, 'token_token')
    
  }, [])

  useEffect(() => {
    location.search.split('=')[2] && getPaymentByIdStatus(paymentId);
  },[paymentId])

  useEffect(() => {
    if (locationPhone && !isLOcationPhoneSaved) {
      console.log('number 2');
      loginAction({phone: locationPhone});
      setIsLocationPhoneSaved(true);
    }

    if (sendCodeReqStat === AsyncStatus.SUCCESS) {

      console.log("send code req stat sucesss");
      payments.forEach((el) => {
        if (el.is_active) {
          lenght.current = lenght.current + 1;
        }
      });
      activePaymentIdAction(paymentId)
      setActivePayment({} as Payment);

      if (/abn=[0-9]{11}&payment=[0-9]+/.test(location.search)) {
        console.log('link 1');
        history.push('/home');
        console.log(payments,"active payment HOME_HOME");
        console.log(getPaymentByIdStatus(paymentId))
        console.log(location.search.split('=')[2], 'qewrfevrtbgvsgrstbgsrtbgvrstbgfrstdbsfrbgrstgbfstb')
        resetLoginAsyncStatus();
        dipslayHomeStatus(true);
      } else if (lenght.current === 1) {
        console.log('link 2');
        history.push('/home');
        console.log(payments,"active payment HOME_HOME");
        getPaymentByIdStatus(paymentId);
        activePaymentAction(payments[0]);
        resetPayment();
        dipslayHomeStatus(true);
      } else if (lenght.current > 1) {
        console.log('link 3');
        history.push('/payment-history');
        dipslayHomeStatus(false);
      } else if (lenght.current === 0) {
        history.push('/payment-history');
        dipslayHomeStatus(false);
      }
      // if(/\?abn=[0-9]{11}/.test(location.search)){
      //   history.push('/home');
      //   resetLoginAsyncStatus();
      // } else {
      //   history.push('/payment-history');
      // }
      console.log('number 1');
      resetSendCodeAsyncStatus();
    }

    console.log('send code req status', sendCodeReqStat);
  }, [sendCodeReqStat]);

  const onCodeInputChange = (val: string) => {
    setCode(val);
  };

  const onLoginButtonClick = () => {
    sendCodeAction({phone: abn, code});
  };

  const onResetCodeClick = () => {
    loginAction({phone: abn});
    window.location.reload()
  };

  return (
    <Wrapper>
      <HeaderWraper>
        <HeaderMain />
      </HeaderWraper>
      <PageWrapper></PageWrapper>
      <PageWrapper>
        {
          location.search.split('=')[2] 
          ? null
          : <GoBack />
        }
        
        <CenterView>
          <Logo />
          <PageHeader>Log in verification</PageHeader>
          <Text>
            Let’s verify it’s you. Please enter the code from
            <br />
            the SMS we’ve just sent to {phone}
          </Text>
          <CodeInput onChange={onCodeInputChange} value={code} />
          <ResentCode onClick={onResetCodeClick}>Resend the code</ResentCode>
          {sendCodeErrorMessages && !!sendCodeErrorMessages.length && (
            <ErrorMessage message={sendCodeErrorMessages[0]} />
          )}
          <Button
            text="Log in"
            onClick={onLoginButtonClick}
            loading={sendCodeReqStat === AsyncStatus.LOADING}
          />
        </CenterView>
      </PageWrapper>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%;
`;

const HeaderWraper = styled.div`
  width: 100%;
  height: 50vh;
`;

const PageHeader = styled.h2`
  font-size: 28px;
  font-weight: 600;
  line-height: 21px;
  color: ${Theme.colors.black2};
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 21px;
  margin: 16px 0 26px 0;
  color: ${Theme.colors.black3};
  text-align: center;
  padding: 0 6px;

  @media (max-width: 354px) {
    font-size: 12px;
  }
`;

const ResentCode = styled.p`
  font-size: 13px;
  line-height: 13px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${Theme.colors.purple};
  margin: 36px 0 33px 0;
  cursor: pointer;
`;

const CenterView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
