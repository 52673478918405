import {Action} from 'deox';
import {useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {ActionCreator, bindActionCreators} from 'redux';

export const useAction = <T extends ActionCreator<Action<string>>>(
  action: T,
): T => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(action, dispatch), [
    action,
    dispatch,
  ]);
};
