/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Theme} from 'src/theme';
import {sendFeedback} from 'src/store/contactUs/actions';
import {useAction} from 'src/utils/hooks';
import {ErrorMessage, Button} from 'src/components';
import {RootState} from 'src/store/types';
import {AsyncStatus} from 'src/types';
import {useSelector} from 'react-redux';
import Modal from 'react-modal';

interface ContactUsProps {}

export const ContactUs = React.memo<ContactUsProps>(() => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const sendFeedbackAction = useAction(sendFeedback.request);
  const {contactUsErrorMessages} = useSelector(
    (state: RootState) => state.errors,
  );

  Modal.setAppElement('#root');

  const {send} = useSelector((state: RootState) => state.contact);
  const resetSendFeedbackAsyncStatus = useAction(sendFeedback.reset);

  const sendFeedbackClick = () => {
    sendFeedbackAction({
      first_name: name,
      last_name: lastName,
      email: email,
      message: message,
    });
  };

  useEffect(() => {
    if (send === AsyncStatus.SUCCESS) {
      setModalIsOpen(true);
      setName('');
      setLastName('');
      setMessage('');
      setEmail('');
      resetSendFeedbackAsyncStatus();
    }
  }, [send]);

  return (
    <Wrapper>
      <Header>Contact us.</Header>
      <HeaderBottom></HeaderBottom>
      <ContentWrapper>
        <InputContainer>
          <InputWrapper>
            <InputLabel>First Name*</InputLabel>
            <Inp
              value={name}
              onChange={(e: any) =>
                (e.target.value || e.target.value === '') &&
                setName(e.target.value)
              }
              type="text"
            />
            {contactUsErrorMessages &&
              !!contactUsErrorMessages.detail &&
              !!contactUsErrorMessages.detail.first_name && (
                <ErrorMessage
                  message={contactUsErrorMessages.detail.first_name}
                />
              )}
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Last Name*</InputLabel>
            <Inp
              value={lastName}
              onChange={(e: any) =>
                (e.target.value || e.target.value === '') &&
                setLastName(e.target.value)
              }
              type="text"
            />
            {contactUsErrorMessages &&
              !!contactUsErrorMessages.detail &&
              !!contactUsErrorMessages.detail.last_name && (
                <ErrorMessage
                  message={contactUsErrorMessages.detail.last_name}
                />
              )}
          </InputWrapper>
        </InputContainer>
        <InputWrapper>
          <InputLabel>Email*</InputLabel>
          <Inp
            value={email}
            onChange={(e: any) =>
              (e.target.value || e.target.value === '') &&
              setEmail(e.target.value)
            }
            type="text"
          />
          {contactUsErrorMessages &&
            !!contactUsErrorMessages.detail &&
            !!contactUsErrorMessages.detail.email && (
              <ErrorMessage message={contactUsErrorMessages.detail.email} />
            )}
        </InputWrapper>
        <AreaWrapper>
          <InputLabel>Message*</InputLabel>
          <TexInp
            value={message}
            onChange={(e: any) =>
              (e.target.value || e.target.value === '') &&
              setMessage(e.target.value)
            }
          />
          {contactUsErrorMessages &&
            !!contactUsErrorMessages.detail &&
            !!contactUsErrorMessages.detail.message && (
              <ErrorMessage message={contactUsErrorMessages.detail.message} />
            )}
        </AreaWrapper>
        <Btn onClick={() => sendFeedbackClick()}>Send</Btn>
      </ContentWrapper>
      <Modal
        isOpen={modalIsOpen}
        // onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <Text>Message received!</Text>
        <TextDown>Thanks for your message. One of our friendly team members will be in touch soon.</TextDown>
        <Button onClick={() => setModalIsOpen(!modalIsOpen)} text="OK" />
      </Modal>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%;
  height: 81vh;
  display: flex;
  flex-direction: column;
  background-color: rgb(238, 237, 235);
  @media (max-width: 454px) {
    height: auto;
  };
`;

const Header = styled.div`
  width: 100%;
  color: white;
  font-size: 60px;
  height: 15vh;
  background-color: #113958;
  padding: 15px 50px;
  display: flex;
  top: 20vh;
`;

const HeaderBottom = styled.div`
  width: 100%; 
  height: 16vh;
  background-color: rgb(238, 237, 235);  
  top: 35vh;
  @media (max-width: 454px) {
    height: 5vh;
  };
`;

const ContentWrapper = styled.div`
  height: 73vh;
  background-color: rgb(238, 237, 235);
  max-width: 820px;
  width: 100%;
  margin: auto;
  padding: 0 20px 40px 20px;
  @media (max-width: 454px) {
    height: 61vh;
  };
`;

const Inp = styled.input`
  height: 42px;
  width: 100%;
  background-color: ${Theme.colors.white};
  border-radius: 2px;
  border: 1px solid rgb(204, 204, 204);
  color: ${Theme.colors.black2};
  font-size: 14px;
  line-height: 14px;
  padding: 17px 20px;
`;
const TexInp = styled.textarea`
  height: 120px;
  width: 100%;
  background-color: ${Theme.colors.white};
  border-radius: 2px;
  border: 1px solid rgb(204, 204, 204);
  color: ${Theme.colors.black2};
  font-size: 14px;
  line-height: 14px;
  padding: 17px 20px;
  @media (max-width: 454px) {
    height: 100px;
  };
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
  padding: 25px 30px;
  height: 91px;
  @media (max-width: 454px) {
    height: 75px;
  };
`;

const AreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
  padding: 25px 30px;
  height: 160px;
`;

const InputLabel = styled.label`
  font-size: 13px;
  line-height: 13px;
  color: ${Theme.colors.black2};
  margin-bottom: 4px;
`;

const InputContainer = styled.label`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
`;

const Btn = styled.button`
  width: 100px;
  height: 70px;
  background-color: black;
  color: white;
  margin-left: 30px;
  @media (max-width: 454px) {
    height: 50px;
  };
`;

const Text = styled.h2`
  width: 300px;
  height: 60px;
  text-align: center;
`;
const TextDown = styled.h2`
  width: 300px;
  height: 95px;
  text-align: center;
  @media (max-width: 454px) {
    height: 130px;
  };
`;
