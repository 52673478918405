import {createAction} from 'deox';

export const sendFeedback = {
  request: createAction(
    'contact/SEND_FEEDBACK',
    (resolve) => (payload: {
      first_name: string;
      last_name: string;
      email: string;
      message: string;
    }) => resolve(payload),
  ),
  success: createAction('contact/SEND_FEEDBACK_SUCCESS', (resolve) => () =>
    resolve(),
  ),
  fail: createAction(
    'contact/SEND_FEEDBACK_FAIL',
    (resolve) => (payload: string[]) => resolve(payload),
  ),
  reset: createAction('contact/SEND_FEEDBACK_RESET'),
};
