import {createReducer} from 'deox';
import {produce} from 'immer';
import {login, sendCode} from '../auth/actions';
import {sendFeedback} from '../contactUs/actions';
import {ErrorsProps} from './types';

const initialState: ErrorsProps = {
  loginErrorMessages: [],
  sendCodeErrorMessages: [],
  contactUsErrorMessages: [],
};

export const errorsReducer = createReducer(initialState, (handleAction) => [
  //AUTH
  handleAction(login.fail, (state, {payload}) =>
    produce(state, (draft) => {
      draft.loginErrorMessages = payload;
    }),
  ),
  handleAction(sendCode.fail, (state, {payload}) =>
    produce(state, (draft) => {
      draft.sendCodeErrorMessages = payload;
    }),
  ),
  handleAction(sendFeedback.fail, (state, {payload}) =>
    produce(state, (draft) => {
      draft.contactUsErrorMessages = payload;
    }),
  ),
]);
