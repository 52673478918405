import React from 'react';
import {useSelector} from 'react-redux';
import {Switch, Route, Redirect} from 'react-router-dom';

import {
  Home,
  Login,
  LoginVerify,
  NewPayment,
  PaymentHistory,
  DirectPayment,
  BusinessInfo,
  IdentityProof,
  ApplicationSubmitted,
  IllionIframe,
  Ezidebit,
  PaymentSchedule,
  Privacy,
  Terms,
  ContactUs,
  Faq,
} from 'src/containers';
import {RootState} from 'src/store/types';
//routes

const AppRouter = ({setPage}:any) => {
  const {token} = useSelector((state: RootState) => state.auth);

  return (
    <Switch>
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path="/home" exact>
        <Home />
      </Route>
      <Route path="/login" exact>
        <Login />
      </Route>
      <Route path="/login/verify" exact>
        <LoginVerify />
      </Route>
      <Route path="/privacy-policy" exact>
        <Privacy />
      </Route>
      <Route path="/terms-conditions" exact>
        <Terms />
      </Route>
      <Route path="/contact-us" exact>
        <ContactUs />
      </Route>
      <Route path="/faq" exact>
        <Faq />
      </Route>
      <Switch>
        {!token && <Redirect to={{pathname: '/'}} />}
        <Route path="/new-payment" exact>
          <NewPayment />
        </Route>
        <Route path="/new-payment/payment-schedule" exact>
          <PaymentSchedule />
        </Route>
        <Route path="/new-payment/illion" exact>
          <IllionIframe />
        </Route>
        <Route path="/new-payment/direct-payment" exact>
          <DirectPayment />
        </Route>
        <Route path="/new-payment/ezidebit" exact>
          <Ezidebit />
        </Route>
        <Route path="/new-payment/business-info" exact>
          <BusinessInfo />
        </Route>
        <Route path="/new-payment/identity-proof" exact>
          <IdentityProof />
        </Route>
        <Route path="/new-payment/app-submitted" exact>
          <ApplicationSubmitted />
        </Route>
        <Route path="/payment-history" exact>
          <PaymentHistory setPage={setPage}/>
        </Route>
      </Switch>
    </Switch>
  );
};

export default AppRouter;
