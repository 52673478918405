/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
// import {addDirectPayment} from 'src/store/ezidebit/actions';
import {getActivePayments} from 'src/store/payments/actions';
import { setActivePayment } from 'src/store/payments/actions';
import {Payment} from '../../store/payments/types';


import {PageWrapper} from 'src/components';
import {SubmittedIcon} from 'src/assets';
import {Button} from 'src/components';
import {useAction} from 'src/utils/hooks';
import {RootState} from 'src/store/types';
// import {AsyncStatus} from 'src/types';

export const ApplicationSubmitted = React.memo(() => {
  const history = useHistory();
  const activePaymentAction = useAction(setActivePayment)

  let {activePayments} = useSelector((state: RootState) => state.payments);
  const activePaymentsAction = useAction(getActivePayments.request);
  // const {payInfo, activePayment} = useSelector((state: RootState) => state.payments);
  const [active, setActive] = useState(false);
  
 
  const reloadCount = Number(sessionStorage.getItem('reloadCount')) || 0;
  
  useEffect(() => {
    if(reloadCount < 1) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      setTimeout(() => window.location.reload(),2000);
    } else {
      sessionStorage.removeItem('reloadCount');
    }
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    activePaymentsAction();

    console.log(activePayments.length, 'activePayments.length')

    }
    , [active] );
  

  const hendlerView = () => {
    history.push('/payment-history')
    setActive(!active)
    activePaymentAction({} as Payment);
    setTimeout(() => window.location.reload(),500)
  }



  return (
    <Wrapper>
      <Fragment>
        <PageWrapper>
          <ContentWrapper>
            <Round>
              <SubmittedIcon />
            </Round>
            <AppHeader>Payment application successfully submitted</AppHeader>
            <AppTextCont>
              <AppText>We will notify you in 3 business days.</AppText>
              <AppText>You can track your request status on the home screen.</AppText>
            </AppTextCont>
            <AppTextCont>
              {console.log(activePayments.length,'activePayments.length')}
            {activePayments.length ?
             <AppMessage>You have another unpaid payment</AppMessage>
             : null
            }
            <Button
              onClick={hendlerView}
              text={activePayments.length  ? "View" : "Next" }
            />
            </AppTextCont>
          </ContentWrapper>
        </PageWrapper>
      </Fragment>
    </Wrapper>
  );
});

const Round = styled.div`
  width: 160px;
  height: 160px;
  background-color: #fafafa;
  border-radius: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 105px;
  @media (max-height: 840px) {
    margin-top: 50px;
  }
  @media (max-height: 760px) {
    margin-top: 20px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-self: space-around;
  align-content: space-around;
  align-items: center;
  paddin: 0 40px;
  max-width: 1020px;
  width: 100%;
  margin: auto;
`;

const AppMessage = styled.div`
margin-bottom: 15px;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  text-align: center;
  line-height: 160%;
  color: #5469D4;
`;

const AppHeader = styled.h1`
  margin: 35px 0;
`;

const AppText = styled.div`
margin-bottom: 15px;
  font-weight: 200;
  font-size: 15px;
  text-align: center;
  text-align: center;
  line-height: 160%;
  color: #404040;
`;

const AppTextCont = styled.div`
width: 320px;
  margin-bottom: 30px;
`;

const Wrapper = styled.div`
  height: 82vh;
  max-width: 1020px;
  width: 100%;
  margin: auto;
  overflow: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    background: transparent;
  }
`;
