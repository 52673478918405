import React from 'react';
import styled from 'styled-components';
import Slider from 'rc-slider';

import {Theme} from 'src/theme';
import {moneyAmountFormat} from 'src/helpers';

interface SliderProps {
  min: number;
  max: number;
  value: number;
  onChange(resp: number): void;
  color: string;
  title: string;
  maxAlovedValue: number;
  disabled: boolean;
}

interface OptionWrapperProps {
  marginTop: string;
  marginBottom: string;
}

export const SliderInput = React.memo<SliderProps>(
  ({disabled,min, max, value, onChange, title, maxAlovedValue, color=Theme.colors.red}) => {
    return (
      <Wrapper>
        <OptionWrapper marginTop="24px" marginBottom="11px">
          <Text>{title}</Text>
          <SliderValue>${moneyAmountFormat(value.toFixed(2))}</SliderValue>
        </OptionWrapper>
        <Slider
          disabled={disabled}
          className="owl-carousel"
          onChange={onChange}
          min={min}
          max={max}
          value={value}
          step={0.001}
          handleStyle={{
            height: 26,
            width: 26,
            boxSizing: 'border-box',
            border: `3px solid ${Theme.colors.white}`,
            backgroundColor: `${color}`,
            position: 'absolute',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
            borderRadius: 13,
            top: -10,
            outline: 'none',
          }}
          railStyle={{
            backgroundColor: value === maxAlovedValue ? 'grey' : '#ECECEC',
            width: '100%',
            height: 6,
            borderRadius: 30,
          }}
          trackStyle={{
            backgroundColor: `${color}`,
            width: '100%',
            height: 6,
            marginTop: -6,
            borderRadius: 30,
          }}
        />
        <OptionWrapper marginTop="11px" marginBottom="20px">
          <Text>${moneyAmountFormat(min.toFixed(2))}</Text>
          <Text>${moneyAmountFormat(max.toFixed(2))}</Text>
        </OptionWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const OptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${(props: OptionWrapperProps) => props.marginTop || 0};
  margin-bottom: ${(props: OptionWrapperProps) => props.marginBottom || 0};
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: ${Theme.colors.muted};
`;

const SliderValue = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${Theme.colors.purple};
`;
