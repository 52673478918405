import {createAction} from 'deox';
import { Payment } from './types';

export const login = {
  request: createAction('auth/LOGIN', (resolve) => (payload: {phone: string}) =>
    resolve(payload),
  ),
  success: createAction(
    'auth/LOGIN_SUCCESS',
    (resolve) => (payload: {phone: string; abn: string}) => resolve(payload),
  ),
  fail: createAction('auth/LOGIN_FAIL', (resolve) => (payload: string[]) =>
    resolve(payload),
  ),
  reset: createAction('auth/LOGIN_RESET'),

};

export const setDisplayHome = createAction(
  'payments/DISPLAY_HOME',
  (resolve) => (payload: boolean) => resolve(payload),
);

export const sendCode = {
  request: createAction(
    'auth/SEND_CODE',
    (resolve) => (payload: {phone: string; code: string;}) => resolve(payload),
  ),
  success: createAction(
    'auth/SEND_CODE_SUCCESS',
    (resolve) => (payload: {token: string; refresh: string, payments: Payment[]}) =>
      resolve(payload),
  ),
  fail: createAction('auth/SEND_CODE_FAIL', (resolve) => (payload: string[]) =>
    resolve(payload),
  ),
  reset: createAction('auth/SEND_CODE_RESET'),
};

export const logOut = createAction('auth/SIGN_OUT');
