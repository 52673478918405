import {ActionType, getType} from 'deox';
import {SagaIterator} from 'redux-saga';
import {call, put, takeEvery} from 'redux-saga/effects';
import {Illion} from 'src/services';

import {loginIllion, getInstitutions} from './actions';

function* loginIllionSaga({
  payload: {slug, username, password},
}: ActionType<typeof loginIllion.request>): SagaIterator {
  try {
    const res = yield call(Illion.loginIllion, {slug, username, password});
    res.status === 200
      ? yield put(loginIllion.success({slug, username, password}))
      : yield put(loginIllion.fail([]));
  } catch (e) {
    yield put(loginIllion.fail([]));
  }
}

function* getInstitutionsSaga(): SagaIterator {
  try {
    const res = yield call(Illion.getInstitutions);
    res.status === 200
      ? yield put(getInstitutions.success(res.data))
      : yield put(getInstitutions.fail());
  } catch (e) {
    yield put(getInstitutions.fail());
  }
}

export function* watchIllion(): SagaIterator {
  yield takeEvery(getType(loginIllion.request), loginIllionSaga);
  yield takeEvery(getType(getInstitutions.request), getInstitutionsSaga);
}
