import React from 'react';
import {Provider} from 'react-redux';
import store, {persistor} from './store';
import {PersistGate} from 'redux-persist/integration/react';
import AppContainer from './AppContainer';
import {IdleTimerContainer} from './components/IdleTimerContainer'

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <IdleTimerContainer/>
        <AppContainer />
      </PersistGate>
    </Provider>
  );
}

export default App;
