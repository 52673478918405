import React from 'react';
import styled from 'styled-components';

import {Theme} from 'src/theme';

interface PaymentInfoCharacteristicProps {
  title: string;
  value: string;
}

export const PaymentInfoCharacteristic = React.memo<
  PaymentInfoCharacteristicProps
>(({title, value}) => {
  return (
    <Wrapper>
      <TitleText>{title}</TitleText>
      <ValueText>{value === '$NaN' ? '$0.00' : value}</ValueText>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const TitleText = styled.p`
  font-size: 14px;
  line-height: 21px;
  font-weight: 400px;
  color: ${Theme.colors.muted};
`;

const ValueText = styled(TitleText)`
  color: ${Theme.colors.black2};
`;
