import {createAction} from 'deox';

import {BankInfo, DirectPayment} from './types';

export const getBankInfo = {
  request: createAction('ezidebit/GET_BANK_INFO'),
  success: createAction(
    'ezidebit/GET_BANK_INFO_SUCCESS',
    (resolve) => (payload: BankInfo) => resolve(payload),
  ),
  fail: createAction('ezidebit/GET_BANK_INFO_FAIL'),
};

export const addBankInfo = {
  request: createAction(
    'ezidebit/ADD_BANK_INFO',
    (resolve) => (payload: BankInfo) => resolve(payload),
  ),
  success: createAction(
    'ezidebit/ADD_BANK_INFO_SUCCESS',
    (resolve) => (payload: BankInfo) => resolve(payload),
  ),
  fail: createAction(
    'ezidebit/ADD_BANK_INFO_FAIL',
    (resolve) => (payload: string) => resolve(payload),
  ),
};

export const addDirectPayment = {
  request: createAction(
    'ezidebit/DIRECT_PAYMENT',
    (resolve) => (payload: DirectPayment) => resolve(payload),
  ),
  success: createAction(
    'ezidebit/DIRECT_PAYMENT_SUCCESS',
    (resolve) => (payload: DirectPayment) => resolve(payload),
  ),
  fail: createAction('ezidebit/DIRECT_PAYMENT_FAIL'),
};
