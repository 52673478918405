export const moneyAmountFormat = (payAmount: string) => {
  const payAmountNumber = Number(payAmount);
  const indexOfDot = payAmount.indexOf('.');
  const numberAfterDot = indexOfDot === -1 ? payAmount.slice(indexOfDot) : '';

  return payAmountNumber >= 1000
    ? `${Math.floor(payAmountNumber / 1000)},${payAmount.slice(
        String(Math.floor(payAmountNumber / 1000)).length,
      )}${numberAfterDot}`
    : Number(payAmount).toFixed(2);
};

export const getFormatedSliderValue = (value: number, maxValue: number) => {
  if (maxValue < 500) {
    return value;
  }

  if (maxValue < 5000) {
    return maxValue - Math.floor(value / 10) * 10 < 10
      ? value
      : Math.floor(value / 10) * 10;
  }

  return maxValue - Math.floor(value / 100) * 100 < 100
    ? value
    : Math.floor(value / 100) * 100;
};

export const formatDate = (date: string) => {
  return date.split('-').reverse().join('/');
};
