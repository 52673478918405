import {createAction} from 'deox';

export const loginIllion = {
  request: createAction(
    'illion/LOGIN_ILLION',
    (resolve) => (payload: {
      slug: string;
      username: string;
      password: string;
    }) => resolve(payload),
  ),
  success: createAction(
    'illion/LOGIN_ILLION_SUCCESS',
    (resolve) => (payload: {
      slug: string;
      username: string;
      password: string;
    }) => resolve(payload),
  ),
  fail: createAction(
    'illion/LOGIN_ILLION_FAIL',
    (resolve) => (payload: string[]) => resolve(payload),
  ),
};

export const getInstitutions = {
  request: createAction('illion/GET_INSTITUTIONS'),
  success: createAction(
    'illion/GET_INSTITUTIONS_SUCCESS',
    (resolve) => (payload: string[]) => resolve(payload),
  ),
  fail: createAction('illion/GET_INSTITUTIONS_FAIL'),
};
