export const Theme = {
  colors: {
    white: '#ffffff',
    black: '#000000',
    black2: '#1D1D1D',
    black3: '#404040',
    purple: '#5469D4',
    blue1: '#2F80ED',
    lightGray: '#F0F2F9',
    lightGray2: '#F9F9F9',
    lightGray3: '#FAFAFA',
    muted: '#777777',
    gray: '#B9B9B9',
    gray2: '#A5A5A5',
    red: '#EB5757',
  },
};
