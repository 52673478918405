import {ActionType, getType} from 'deox';
import {SagaIterator} from 'redux-saga';
import {call, put, takeEvery} from 'redux-saga/effects';

import {Ezidebit} from 'src/services/ezidebit';
import {getBankInfo, addDirectPayment, addBankInfo} from './actions';

function* getBankInfoSaga(): SagaIterator {
  try {
    const res = yield call(Ezidebit.getBankInfo);
    
    res.status === 200
      ? yield put(getBankInfo.success(res.data))
      : yield put(getBankInfo.fail());
  } catch (e) {
    yield put(getBankInfo.fail());
  }
}

function* addBankInfoSaga({
  payload,
}: ActionType<typeof addBankInfo.request>): SagaIterator {
  try {
    const res = yield call(Ezidebit.addBankInfo, payload);

    // perhaps change 'paylaod' to 'res.data' for prod data

    res.status === 200
      ? yield put(addBankInfo.success(payload))
      : yield put(
          addBankInfo.fail(typeof res.data === 'string' ? res.data : ''),
        );
  } catch (e) {
    yield put(addBankInfo.fail(''));
  }
}

function* addDirectPaymentSaga({
  payload,
}: ActionType<typeof addDirectPayment.request>): SagaIterator {
  try {
    const res = yield call(Ezidebit.addDirectPayment, payload);
    console.log(res,'resDDPayment')
    res.status === 200
    
      ? yield put(addDirectPayment.success(payload))
      : yield put(addDirectPayment.fail());
  } catch (e) {
    console.log(e,'FAiLED_DD')
    yield put(addDirectPayment.fail());
  }
}

export function* watchEzidebit(): SagaIterator {
  yield takeEvery(getType(getBankInfo.request), getBankInfoSaga);
  yield takeEvery(getType(addBankInfo.request), addBankInfoSaga);
  yield takeEvery(getType(addDirectPayment.request), addDirectPaymentSaga);
}
