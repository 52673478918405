import {LoanPayment} from 'src/store/payments/types';
import Api from './api';

export class Payments {

  static getPayment() {
    return Api.get('business/payment');
  }

  static getPaymentById(id: string) {
    return Api.get(`business/payment/${id}`);
  }

  static getActivePayments() {
    return Api.get(`business/active-payments`);
  }

  static getLoanPayment(id: string) {
    return Api.get(`business/loan-payment/${id}`);
  }

  static addLoanPayment(data: LoanPayment) {
    return Api.post('business/loan-payment', data);
  }

  static getPaymentHistory() {
    return Api.get('/business/history');
  }

  static getCompany() {
    return Api.get('/business/detail');
  }

  static addProofFile(data: FormData) {
    return Api.post('/business/file', data, {
      headers: {'Content-type': 'multipart/form-data'},
    });
  }

  static deleteProofFile (id : number) {
    return Api.delete(`/business/file/${id}`)
  }

  static addPaymentScheduler(data: {amount: string}) {
    return Api.post('business/payment-scheduler', data);
  }

  static getXeroAuth() {
    return Api.get('/external/xero/auth');
  }
}
