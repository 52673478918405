// import React from 'react';
import React, { useState} from 'react';
import styled from 'styled-components';
import {Footer} from 'src/components';
// import {useSelector} from 'react-redux'
import AppRouter from 'src/router';
import {BrowserRouter as Router} from 'react-router-dom';
// import { RootState } from 'src/store/types';

function AppConteiner() {
  // const login = useSelector((state: RootState) => state.auth);
  const [page, setPage] = useState(false)
  return (
    <Router>
      <Wrapper>
        <MainContent> 
        <AppRouter setPage={setPage}/>
        </MainContent>
        <FooterWraper>
          <Footer page={page}/>
        </FooterWraper>
      </Wrapper>
    </Router>
  );
}

export default AppConteiner;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const MainContent = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  @media (max-width: 454px) {
  };
  
`;

const FooterWraper = styled.div`
  height: 18vh;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
`;

// const HeaderWraper = styled.div`
//   width: 100%;
//   height: 50vh;
// `;
