import React from 'react';
import styled from 'styled-components';

import {Theme} from 'src/theme';

interface DotProps {
  animationDelay: string;
}

export const LoadingDots = () => {
  return (
    <DotWrapper>
      <Dot animationDelay="0s" />
      <Dot animationDelay="0.2s" />
      <Dot animationDelay="0.4s" />
    </DotWrapper>
  );
};

const DotWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 21px;
`;

const Dot = styled.div`
  background-color: ${Theme.colors.white};
  border-radius: 50%;
  width: 10px;
  height: 10px;
  marginright: 5px;
  margin-left: 5px;
  animation: OpacityAnimation 1.4s infinite;
  animationdelay: ${(props: DotProps) => props.animationDelay};
`;
