import React from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import {Theme} from 'src/theme';

interface InputProps {
  onChange(e: any): void;
  type: string;
  labelText: string;
  placeholder?: string;
  value: string;
  leftIcon?: string;
  mb0?: boolean;
  mask?: string;
}

interface InputWrapperProps {
  mb0?: boolean;
}

export const Input: React.FC<InputProps> = ({
  onChange,
  type,
  labelText,
  placeholder,
  value,
  leftIcon,
  mb0,
  mask,
}) => {
  return (
    <InputWrapper mb0={mb0}>
      <InputLabel>{labelText}</InputLabel>
      {leftIcon ? (
        <Dummy>
          <LeftIcon>{leftIcon}</LeftIcon>
          <DummyInp
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          />
        </Dummy>
      ) : !!mask ? (
        <InputMask
          type={type}
          maskChar={null}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          mask={mask}
          style={{
            background: '#F0F2F9',
            borderRadius: 2,
            paddingBottom: 10,
            paddingTop: 10,
            width: '100%',
            fontSize: 14,
            paddingLeft: 17,
            color: Theme.colors.black2,
          }}
        />
      ) : (
        <Inp
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
      )}
    </InputWrapper>
  );
};

const Dummy = styled.div`
  display: flex;
  width: 100%;
  padding-left: 20px;
  box-sizing: border-box;
  background-color: ${Theme.colors.lightGray};
  border-radius: 2px;
  box-shadow: inset 0px 4px 14px rgba(167, 167, 167, 0.1);
  align-items: center;
`;

const LeftIcon = styled.p`
  font-size: 14px;
  line-height: 14px;
`;

const DummyInp = styled.input`
  width: 100%;
  color: ${Theme.colors.black2};
  font-size: 14px;
  line-height: 14px;
  padding: 17px 0;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props: InputWrapperProps) => (props.mb0 ? '0' : '13px')};
  width: 100%;
`;

const InputLabel = styled.label`
  font-size: 13px;
  line-height: 13px;
  color: ${Theme.colors.black2};
  margin-bottom: 4px;
`;

const Inp = styled.input`
  width: 100%;
  background-color: ${Theme.colors.lightGray};
  border-radius: 2px;
  box-shadow: inset 0px 4px 14px rgba(167, 167, 167, 0.1);
  color: ${Theme.colors.black2};
  font-size: 14px;
  line-height: 14px;
  padding: 17px 20px;
`;
