import React from 'react';
import styled from 'styled-components';

import {PaymentInfoCharacteristic} from 'src/components';
import {formatDate} from 'src/helpers';
import {useHistory} from 'react-router-dom';
import { useAction } from 'src/utils/hooks';
import { setActivePayment } from 'src/store/payments/actions';
import {Payment} from '../../store/payments/types'
import Checkmark from '../../assets/icons/checkmark.png';

interface PaymentInfoProps {
  typeOfActivity: string;
  period: string;
  dueDate: string;
  totalAmountDue: string;
  payment?: Payment
  button?: boolean;
  color?: boolean;
}


export const PaymentInfo = React.memo<PaymentInfoProps>(
  ({typeOfActivity, period, dueDate, totalAmountDue, button, color, payment}) => {

    const history = useHistory();
const activePaymentAction = useAction(setActivePayment)


    const loanRequestClick = () => {
      history.push({
        pathname: '/new-payment',
        state: { detail: "string" }
      })
      if(payment){
        activePaymentAction(payment);
      }
  };

    return (
      <Wrapper colorOrange={color} button={button} history={history}>
        {!button ?
          <Container>
            <PaymentInfoCharacteristic
              title="Type of activity"
              value={typeOfActivity}
            />
            <PaymentInfoCharacteristic title="Period" value={period} />
            <PaymentInfoCharacteristic
              title="Due date"
              value={formatDate(dueDate)}
            />
            <PaymentInfoCharacteristic
              title="Total amount due"
              value={totalAmountDue}
            />  
          </Container>
          :
          <>
            <PaymentInfoCharacteristic
                title="Type of activity"
                value={typeOfActivity}
              />
              <PaymentInfoCharacteristic title="Period" value={period} />
              <PaymentInfoCharacteristic
                title="Due date"
                value={formatDate(dueDate)}
              />
              <PaymentInfoCharacteristic
                title="Total amount due"
                value={totalAmountDue}
              />  
          </>
        }
        
        {button &&
          <PayButtonContainer>
              <PayButtonCont onClick={loanRequestClick} href="">
                <TextButton>Pay</TextButton>
              </PayButtonCont>
          </PayButtonContainer> 
        }
        {history.location.pathname !== "/new-payment" &&history.location.pathname !== "/home" && !button ?
          <PayButtonContainer>
            <PaidContainer>
              <Image src={Checkmark}/>
              <PaidText>Paid</PaidText>
            </PaidContainer>
          </PayButtonContainer>
          :
          null
        }
      </Wrapper>
    );
  },
);

const Wrapper = styled.div<{colorOrange: any, button: any, history:any}>`
  
  ${(props) => 
    props.button ? `
      height: 200px;
    `
    :
    `height: 100%;`
  }
  ${(props) => 
    (props.history.location.pathname === '/home' || props.history.location.pathname === '/new-payment') && `
      height: 150px;
    `
    
  }
  width: 100%;
  padding: 19px 23px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 3px;
  box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.1);
  ${(props) => 
    !props.button && `padding: 0px;`
  }
`;
const PayButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const PayButtonCont = styled.a`
  border: 2px solid #5469D4;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  text-decoration: none;
  border-radius: 2px;
  background: #5469D4;
`;
const TextButton = styled.p`
  color: white;
  font-size: 13px;
`;
const PaidContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid #F7F7F7;
  height: 55px;
`;
const PaidText = styled.p`
  color: #24A664;
  font-size: 15px;
  font-weight: 600;
`;

const Container = styled.div`
  padding: 18px 23px 8px 21px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;