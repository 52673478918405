import React, {useRef, useEffect} from 'react';
import IdleTimer from 'react-idle-timer';
import {useAction} from 'src/utils/hooks';
import {logOut} from 'src/store/auth/actions';



export const IdleTimerContainer =  () => {
    // const [isLoggedIn, setIsLoggedIn] = useState(true)
    const IdleTimerRef = useRef<HTMLHeadingElement>(null)
    const logoutAction = useAction(logOut);

    useEffect(() => {
        console.log(IdleTimerRef)
        console.log(React)
    },[])


    window.onunload = (ev:any) => {
        console.log("exit")
        logoutAction();
        // setTimeout(() => {logOutAction(); },4000)
    };

    const onIdle = () => {
        console.log('User is idle')
        logoutAction();  

    }
    return(
        <div>
            <IdleTimer timeout={3600 * 1000} onIdle={onIdle}></IdleTimer>
        </div>
    )

};