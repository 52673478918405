import React from 'react';
import styled from 'styled-components'; 

interface TermsProps {}

export const Faq = React.memo<TermsProps>(() => {
  return (
    <Wrapper>
      <Header>
        <Number>1.</Number> What is PayMyTax?
      </Header>
      <Text>
        PayMyTax is a free service that helps you streamline your BAS or IAS payments, and helps you fund your tax payments, should you ever need it. This helps protect your credit score and keep you debt-free with the ATO.
      </Text>
      <Header>
        <Number>2.</Number> Why was PayMyTax built?
      </Header>
      <Text>The way we see it, there are two problems with BAS payments: they’re complicated to make, and they’re virtually impossible to find loans for. We built PayMyTax to make it easier to make your business's BAS payments, and to help you cover payments when cashflow is tight.</Text>
      <Header>
        <Number>3.</Number> What are the benefits of using PayMyTax?
      </Header>
      <Text>Using PayMyTax makes tax payments simple and seamless, whether or not you’re in need of a loan. If you are taking out a loan for your payment, this can help you maintain a strong lodgment and payment history with the ATO, and free up your business’s cashflow through smaller, incremental payments. With PayMyTax, <span style={{textDecoration: 'underline'}}>you</span> get to decide what you want to pay now, and what you’d like to pay later through weekly instalments.</Text>
      <Header>
        <Number>4.</Number> Does it cost money to use PayMyTax for my tax payments?
      </Header>
      <Text>
        Nope! It’s free to set up your PayMyTax profile and schedule ATO payments. And if at any point you need help making payments, you can choose what you pay now, and how much you pay later through flexible weekly instalments. You’ll simply pay interest on the amount you choose to pay later.
      </Text>
      <Header>
        <Number>5.</Number> Is PayMyTax safe?
      </Header>
      <Text>
        Yes, it is. We’re compliant with all industry guidelines, and our tech is built according to cloud software best practice, which means your personal and financial details are kept completely safe. For more details, read our privacy policy.
      </Text>

      <Header>
        <Number>6.</Number> What is the interest rate and terms of the loan to cover my BAS?
      </Header>
      <Text> It’s very difficult to find loans for tax bills through standard business banking channels, which is why we built PayMyTax. The interest rates and terms of your PayMyTax loan are competitive to the current market rates for unsecured short-term loans (up to 3 months).</Text>
      <Text>
        You’ll always know what you’re signing up for, and you’re in control of how and when you make payments on your loan, setting up your preferred weekly payment amount to suit your budget and cashflow. In addition, your interest rates and payment terms will improve with each loan you take out.
      </Text>

      <Header>
        <Number>7.</Number> What do I need to do to be approved for a loan through PayMyTax?
      </Header>
      <Text>
        Applying for a loan through PayMyTax is quick and easy. Just fill in a few personal details, upload your ID, and provide 12 months of bank statements, and you’ll get your approval notification the same day, or the following day.
      </Text>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%; 
  font-family: adonis-web;
  height: 100%;
  background-color: #113958;
  padding: 50px 150px;
  overflow: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    background: transparent;
  }
  @media (max-width: 900px) {
    padding: 50px 50px;
  }
  @media (max-width: 450px) {
    padding: 30px 30px;
  }
`;

const Text = styled.p`
  font-size: calc((1 - 1) * 1.2vw + 1rem);
  color: white; 
  font-weight: 400;
  color: #d3d3d3;
  padding: 5px 0;
`;

const Header = styled.h3` 
color: white;
font-weight: 400;
font-size: calc((1.6 - 1) * 1.2vw + 1rem);
padding: 30px 2px;
`;

const Number = styled.div`
  color: white;
  display: inline-block;
  font-weight: 400;
  padding-right: 10px;
`;
