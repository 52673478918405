import {createReducer} from 'deox';
import {produce} from 'immer';
import {AsyncStatus} from 'src/types';

import {sendFeedback} from './actions';
import {ContactUsProps} from './types';

const initialState: ContactUsProps = {
  first_name: '',
  last_name: '',
  email: '',
  message: '',
  send: AsyncStatus.NONE,
};

export const contactUsReducer = createReducer(initialState, (handleAction) => [
  handleAction(sendFeedback.request, (state) =>
    produce(state, (draft) => {
      draft.send = AsyncStatus.LOADING;
    }),
  ),
  handleAction(sendFeedback.success, (state) =>
    produce(state, (draft) => {
      draft.send = AsyncStatus.SUCCESS;
    }),
  ),
  handleAction(sendFeedback.fail, (state) =>
    produce(state, (draft) => {
      draft.send = AsyncStatus.FAIL;
    }),
  ),

  handleAction(sendFeedback.reset, (state) =>
    produce(state, (draft) => {
      draft.send = AsyncStatus.NONE;
    }),
  ),
]);
