import React from 'react';
import styled from 'styled-components';
import ImageUploader from 'react-images-upload';

import {Button} from 'src/components';
import {Theme} from 'src/theme';
// import {useAction} from 'src/utils/hooks';
// import {addProofFile} from 'src/store/payments/actions';

interface ProofItemProps {
  options: string[];
  id: number;
  points: string;
  type: number;
  isAvailable: boolean;
  addPoints(val: number): void;
  onSelectImage: any
}

export const ProofItem = React.memo<ProofItemProps>(
  ({options, id, points, type, addPoints, isAvailable, onSelectImage}) => {
    // const proofFileAction = useAction(addProofFile.request);

    const proofOptionsList = options.map((elem, index) => (
      <ProofOption key={index}>{elem}</ProofOption>
    ));

    // const onSelectImage = (files: File[], image: string[]) => {
    //   const [img] = image;
    //   const [file] = files;
       

    //   if (img) {
    //     addPoints(Number(points));

    //     let formData = new FormData();
    //     formData.append('id', `${id}`);
    //     formData.append('points', points);
    //     formData.append('type', `${type}`);
    //     formData.append('file', file);
    //     formData.append('customer', `3324`);

    //     proofFileAction(formData);
    //   }
    // };
    return (
      <ProofWrapper>
        <ProofHeaderContainer>
          <ProofHeaderText>{`${points} points`}</ProofHeaderText>
          {isAvailable ? (
            <UploadImageContainer>
              <ImageUploader
                buttonText="Upload"
                singleImage={true}
                onChange={(File, Image) => onSelectImage(File, Image, points, id)}
                imgExtension={['.jpg', '.png', '.jpeg']}
                label=""
                withIcon={false}
                fileContainerStyle={{
                  maxWidth: '135px',
                  margin: '0',
                  padding: 0,
                  display: 'block',
                  overflow: 'hidden',
                  borderRadius: '2px',
                  boxShadow: 'none',
                }}
                buttonStyles={{
                  width: '135px',
                  height: '40px',
                  borderRadius: '2px',
                  backgroundColor: Theme.colors.purple,
                  boxShadow: '0px 6px 14px rgba(84, 105, 212, 0.1)',
                  cursor: 'pointer',
                  margin: '0',
                  fontSize: '13px',
                  lineHeight: '18px',
                }}
              />
            </UploadImageContainer>
          ) : (
            <Button
              text="Done"
              onClick={() => {}}
              buttonStyles={{
                maxWidth: '135px',
                maxHeight: '40px',
                backgroundColor: Theme.colors.gray,
              }}
              buttonTextStyles={{fontSize: '13px', color: Theme.colors.black}}
            />
          )}
        </ProofHeaderContainer>
        <ProofOptions>{proofOptionsList}</ProofOptions>
      </ProofWrapper>
    );
  },
);

const ProofWrapper = styled.div`
  width: 100%;
  padding: 19px 22px 27px;
  box-shadow: 0px 2px 6px #e5eaee;
  margin-bottom: 17px;
`;

const ProofHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
  max-height: 40px;
`;

const ProofHeaderText = styled.h3`
  font-size: 19px;
  line-height: 18px;
  font-weight: 600;
`;

const UploadImageContainer = styled.div`
  align-self: flex-end;
  max-width: 135px;
`;

const ProofOptions = styled.ul``;

const ProofOption = styled.li`
  font-size: 13px;
  line-height: 20.8px;
  color: ${Theme.colors.black3};
  font-weight: 400;
  list-style-type: none;
`;
