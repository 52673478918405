import {getType} from 'deox';
import {Action, combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {resetStore} from './app/actions';
import {RootState} from './types';
import {authReducer} from './auth/reducer';
import {PaymentsReducer} from './payments/reducer';
import {illionReducer} from './illion/reducer';
import {errorsReducer} from './errors/reducer';
import {EzidebitReducer} from './ezidebit/reducer';
import {contactUsReducer} from './contactUs/reducer';

const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['errors', 'ezidebit'],
};

const projectReducer = combineReducers({
  auth: authReducer,
  errors: errorsReducer,
  payments: PaymentsReducer,
  illion: illionReducer,
  ezidebit: EzidebitReducer,
  contact: contactUsReducer,
});

const rootReducer = (
  state: RootState | undefined,
  action: Action,
): ReturnType<typeof projectReducer> => {
  if (action.type === getType(resetStore)) {
    state = undefined;
  }
  return projectReducer(state, action);
};

export default persistReducer(rootPersistConfig, rootReducer);
